import { MonthEventsCalendar } from "./types";

export const improEventsTable: Array<MonthEventsCalendar> = [
  {
    monthName: "Červenec 2023",
    monthDate: "2023-07",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Camp improvizace s Martinem Vasquezem a Andreou Moličovou",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/256600993556480",
        organizerIds: ["paletaci"],
        playDate: "2023-07-24",
        district: "Středočeský kraj",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Živelné impro",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/272377468513550",
        organizerIds: ["polocasnapadu"],
        playDate: "2023-07-29",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Červen 2023",
    monthDate: "2023-06",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Kecky - improvizovaný večer. Host Láďa Karda",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/708266527438722",
        organizerIds: ["kecky"],
        playDate: "2023-06-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Xena / celovečerní improvizovaná hra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/708266527438722",
        organizerIds: ["improvariace"],
        playDate: "2023-08-08",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vyšetřování inspektora Parkera - improvizovaná divadelní detektivka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/189896543998939",
        organizerIds: ["vip"],
        playDate: "2023-06-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Nášup!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/954218659038417",
        organizerIds: ["alibiprochyby"],
        playDate: "2023-06-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Ale to jsem měl říct já!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/267509755761424",
        organizerIds: ["polocasnapadu"],
        playDate: "2023-06-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Jedničky a nuly | Pade na Pade s umělou inteligencí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1397373071052582",
        organizerIds: ["padenapade"],
        playDate: "2023-06-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/829195705484892",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-06-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Fofr na moři",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2554930714671059",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-06-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Camp improvizace s Martinem Vasquezem a Andreou Moličovou",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/256600993556480",
        organizerIds: ["paletaci"],
        playDate: "2023-07-24",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Adam & Pája /IMPRO/",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2731074617034818",
        organizerIds: [],
        playDate: "2023-06-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Klasika",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/657371099056530",
        organizerIds: ["cnid"],
        playDate: "2023-06-28",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Květen 2023",
    monthDate: "2023-05",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "IMPROTŘESK 2023 | Festival improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/531202315642360",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2023-05-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Dítě 19",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/177505508470079",
        organizerIds: ["alibiprochyby"],
        playDate: "2023-05-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1413760719454916",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-05-25",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Duben 2023",
    monthDate: "2023-04",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Kecky - improvizovaný večer. Host Ondřej Nečas",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/716910450033344",
        organizerIds: ["kecky"],
        playDate: "2023-04-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "1",
        name: "Impro Ambasadors - workshop s Gerit (AUT)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/5553856491387154",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-04-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "2",
        name: "Impro Ambasadors - představení: NO EXIT",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1235463783774029",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-04-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "The BLR Improv Comedy Brew!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/498524245699846",
        organizerIds: ["blr"],
        playDate: "2023-04-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Amorovy katastrofy - improshow o lásce",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/734131978403488",
        organizerIds: ["vip"],
        playDate: "2023-04-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/745594463834172",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-04-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "improvizační show NeDivSe",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1261275181493337",
        organizerIds: ["nedivse"],
        playDate: "2023-04-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "WORKSHOP s Českým Národním Improvizačním Divadlem",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/228312459568330 ",
        organizerIds: ["cnid"],
        playDate: "2023-04-29",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Březen 2023",
    monthDate: "2023-03",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Kecky - improvizační večer TAK TROCHU JINÝ MDŽ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1160111454869033",
        organizerIds: ["kecky"],
        playDate: "2023-03-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Čtenářský kroužek - improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1242644813273604",
        organizerIds: ["vip"],
        playDate: "2023-03-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/709375650921842",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-03-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Instantní Gaučink - 26. 3. 2023",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/511867941109287",
        organizerIds: ["instantnigaucink"],
        playDate: "2023-03-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improkabaret: Souboj pohlaví",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/124974393641876",
        organizerIds: ["padenapade"],
        playDate: "2023-03-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Únor 2023",
    monthDate: "2023-02",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "NeDivSe: Vyplouváme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/705524674490028",
        organizerIds: ["nedivse"],
        playDate: "2023-02-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Valentýnský IMPROspeciál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1297172470851314",
        organizerIds: ["improvariace"],
        playDate: "2023-02-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kecky - improvizační večer",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/861496178405714",
        organizerIds: ["kecky"],
        playDate: "2023-02-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/536862931876454",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-02-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Večer sedmi hříchů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/968205434155676",
        organizerIds: ["padenapade"],
        playDate: "2023-02-27",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Leden 2023",
    monthDate: "2023-01",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Prezidentský IMPROspeciál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/726550952421031",
        organizerIds: ["improvariace"],
        playDate: "2023-01-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/491611799747111",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-01-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vyšetřování inspektora Parkera - improvizovaná detektivní hra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/957439238557530",
        organizerIds: ["vip"],
        playDate: "2023-01-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improsquatting",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1307133426796816",
        organizerIds: ["padenapade"],
        playDate: "2023-01-31",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Srpen 2023",
    monthDate: "2023-08",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Impro v parku",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/improvparku",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-08-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/245946518251331/245946521584664",
        organizerIds: ["improvariace"],
        playDate: "2023-08-08",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro v parku",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/improvparku",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-08-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow: Best of Fofr v županu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/9671342792940933",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-08-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Večerní degustace impra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/308663351728541",
        organizerIds: [],
        playDate: "2023-08-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Letní intenzivní kurz 2023",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/letniskola",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-08-20",
        district: "Středočeský kraj",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro v parku",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/improvparku",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-08-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vyletněná Improshow",
        eventType: "play",
        websiteUrl: "https://www.odvaz.eu/akce?&idA=1397",
        organizerIds: ["divadloodvaz"],
        playDate: "2023-08-21",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/245946518251331/245946528251330",
        organizerIds: ["improvariace"],
        playDate: "2023-08-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro v parku",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/improvparku",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-08-28",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Letní Letná",
        eventType: "play",
        websiteUrl: "https://vosto5.cz/repertoar/standartni-kabaret",
        organizerIds: ["standartnikabaret"],
        playDate: "2023-08-28",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Září 2023",
    monthDate: "2023-09",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Divadlo 301: Dřez v Nymburce",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1678866019286817",
        organizerIds: ["divadlo301"],
        playDate: "2023-09-02",
        district: "Nymburk",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro Kecky a Pája Sedláčková",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1026480205026852",
        organizerIds: ["kecky"],
        playDate: "2023-09-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divadlo 301: Dřez",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/203724492427888",
        organizerIds: ["divadlo301"],
        playDate: "2023-09-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vosto5: Stand'artní kabaret",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1485737302229061",
        organizerIds: ["standartnikabaret"],
        playDate: "2023-09-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Otevřená hodina improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1007349770286496",
        organizerIds: ["paletaci"],
        playDate: "2023-09-06",
        district: "Hradec Králové",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow: třídní sraz",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/improshow-tridni-sraz/sziuhiw/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2023-09-06",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Ukázková lekce",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/ukazkove-lekce",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-09-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Otevřená hodina improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1377078153227429",
        organizerIds: ["paletaci"],
        playDate: "2023-09-07",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Ozvěny léta",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1391961111535867",
        organizerIds: ["alibiprochyby"],
        playDate: "2023-09-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Klasika vol. 2",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/962783754798105",
        organizerIds: ["cnid"],
        playDate: "2023-09-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divadelní improvizace",
        eventType: "workshop",
        websiteUrl:
          "https://zivapedagogika.cz/kurzy-new/23-divadelni-improvizace-pro-ucitele-a-nejen-pro-ne/",
        organizerIds: ["zivapedagogika"],
        playDate: "2023-09-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Generál Torstenson Impro Cup",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/267767889369877",
        organizerIds: ["bafni"],
        playDate: "2023-09-13",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Maestro",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/maestro/szguhiw/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2023-09-13",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Maestro! Festival ImVisible (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/212420504894469",
        organizerIds: ["improvariace", "blr"],
        playDate: "2023-09-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizační show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/264589383185599",
        organizerIds: ["divadlokellari"],
        playDate: "2023-09-15",
        district: "Benešov",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vosto5: Stand'artní kabaret",
        eventType: "play",
        websiteUrl: "https://vzlet.cz/program/volna-jizda-vol-2-festival-ciste-improvizace",
        organizerIds: ["standartnikabaret"],
        playDate: "2023-09-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow - Vyber si svůj příběh",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/180900268341467",
        organizerIds: ["bizoni"],
        playDate: "2023-09-15",
        district: "Hradec Králové",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Z nuly na pade, z pade na sto",
        eventType: "play",
        websiteUrl: "https://vzlet.cz/program/volna-jizda-vol-2-festival-ciste-improvizace",
        organizerIds: ["padenapade"],
        playDate: "2023-09-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Klasika",
        eventType: "play",
        websiteUrl: "https://vzlet.cz/program/volna-jizda-vol-2-festival-ciste-improvizace",
        organizerIds: ["cnid"],
        playDate: "2023-09-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Svědomitě nepřipraveni",
        eventType: "play",
        websiteUrl: "https://vzlet.cz/program/volna-jizda-vol-2-festival-ciste-improvizace",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2023-09-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Zápas v divadelní improvizaci MUŽI vs. ŽENY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/294409953182405",
        organizerIds: ["bafni"],
        playDate: "2023-09-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl:
          "https://www.smsticket.cz/vstupenky/37709-paletaci-improshow-divadlo-exil-machonova-pasaz-pardubice",
        organizerIds: ["paletaci"],
        playDate: "2023-09-19",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Ukázková lekce",
        eventType: "workshop",
        websiteUrl: "https://www.skolaimprovizace.cz/ukazkove-lekce",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-09-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Motel Babí léto",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/149181438240378",
        organizerIds: ["polocasnapadu"],
        playDate: "2023-09-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Accio Impro (work in progress)",
        eventType: "play",
        websiteUrl: " https://www.facebook.com/events/347284567727787",
        organizerIds: [],
        playDate: "2023-09-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kurz improvizace 1",
        eventType: "coursework",
        websiteUrl: "https://kurzy.paletaci.cz/kurz-impra-1/",
        organizerIds: ["paletaci"],
        playDate: "2023-09-20",
        district: "Hradec Králové",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow: detektivka",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/improshow-detektivka/szkuhiw/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2023-09-20",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kurz improvizace 1",
        eventType: "coursework",
        websiteUrl: "https://kurzy.paletaci.cz/kurz-impra-1/",
        organizerIds: ["paletaci"],
        playDate: "2023-09-21",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizační workshop pro začátečníky",
        eventType: "workshop",
        websiteUrl: "https://improvizacnikurzy.cz/kurzy/vikendy-pro-zacatecniky-praha/",
        organizerIds: ["bafni"],
        playDate: "2023-09-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1120142216038883",
        organizerIds: ["lisky"],
        playDate: "2023-09-22",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro JAM",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/3564104473845369",
        organizerIds: ["vip"],
        playDate: "2023-09-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro Talk Show: David Matásek",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/321jedem-david-matasek/szgdmvv",
        organizerIds: ["321jedem"],
        playDate: "2023-09-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kluci na lodi: Poslední věneček",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/posledni-venecek/szkdymv",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2023-09-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kroužek pro děti",
        eventType: "coursework",
        websiteUrl: "https://kurzy.paletaci.cz/pro-deti/",
        organizerIds: ["paletaci"],
        playDate: "2023-09-26",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Fofr v Hollywoodu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2033706753675482",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-09-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Přijď mezi nás! Otevřený trénink",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/305816592033322/305821975366117",
        organizerIds: ["frmol"],
        playDate: "2023-09-26",
        district: "Frýdek-Místek",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizujeme s Petrem Budilem",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/692599146058545",
        organizerIds: ["improvizacecz"],
        playDate: "2023-09-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Mixer - workshop & open mic (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/973688560465584/973688570465583",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-09-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Manželské etudy",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/manzelske-etudy/szduhiw/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2023-09-27",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Let's Get Personal (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1996941320666640",
        organizerIds: ["imperfectionists"],
        playDate: "2023-09-28",
        district: "Praha",
        language: "en",
      },
    ],
  },
  {
    monthName: "Říjen 2023",
    monthDate: "2023-10",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "Živelné impro",
        eventType: "workshop",
        websiteUrl: "https://www.kabinetimprovizace.cz/",
        organizerIds: ["kabinetimprovizace"],
        playDate: "2023-10-01",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Nebezpečné vztahy (ProArt festival)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/666785172044916",
        organizerIds: ["impra"],
        playDate: "2023-10-01",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Workshop - Let's be evil (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/2100863010255216",
        organizerIds: [],
        playDate: "2023-10-01",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Jam (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/1073538563813633",
        organizerIds: [],
        playDate: "2023-10-01",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "IMPROvizace k Osobnostnímu rozvoji",
        eventType: "coursework",
        websiteUrl: "https://www.skola21.com/udalosti/improvizace-k-osobnostnimu-rozvoji",
        organizerIds: ["skola21"],
        playDate: "2023-10-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Poprvé",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3747334172180968",
        organizerIds: ["divadlo301"],
        playDate: "2023-10-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Gilty Pležr: Telenovela",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1737547963377081",
        organizerIds: ["improvariace", "cnid"],
        playDate: "2023-10-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Acting Workshop (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1081707506132401",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-10-03",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divadelní improvizace",
        eventType: "coursework",
        websiteUrl: "https://zivapedagogika.cz/kurzy-new/01-divadelni-improvizace/",
        organizerIds: ["zivapedagogika"],
        playDate: "2023-10-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "The Randoms improvizují",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1290023418374461",
        organizerIds: ["therandoms"],
        playDate: "2023-10-04",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Tajemství Vesmíru (Space sheep)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2571374433019341",
        organizerIds: ["saysomethingcz"],
        playDate: "2023-10-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "ALT + F4: Setkání",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3399162263747880",
        organizerIds: ["altf4"],
        playDate: "2023-10-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Dopolední Rozlety s Peťou",
        eventType: "coursework",
        websiteUrl: "https://www.facebook.com/events/170997519240343",
        organizerIds: [],
        playDate: "2023-10-05",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Thursday Night Improv Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/621985480107410",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-10-05",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Poločas nápadu vs. B.I.Z.O.N.I.",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1658799717958012",
        organizerIds: ["polocasnapadu", "bizoni"],
        playDate: "2023-10-06",
        district: "Mnichovo Hradiště",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "October Improv Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1411115936112154",
        organizerIds: ["creativedifferences"],
        playDate: "2023-10-07",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow Meandrů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/876910106754584",
        organizerIds: ["meandry"],
        playDate: "2023-10-11",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improkruháč - Otevřený trénink improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.vimcochci.cz/akce/improkruhac/2023-10-11/",
        organizerIds: [],
        playDate: "2023-10-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Svědomitě nepřipravený kabaret",
        eventType: "play",
        websiteUrl: "https://vosto5.cz/repertoar/standartni-kabaret",
        organizerIds: ["standartnikabaret"],
        playDate: "2023-10-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Pátek třináctého",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/340711351723167",
        organizerIds: ["alibiprochyby"],
        playDate: "2023-10-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "ImproZÁKUSKY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/330475502809549",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-10-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro pro starý stromy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1041026333589549 ",
        organizerIds: [],
        playDate: "2023-10-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Single Location Montageness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1042042583472678",
        organizerIds: ["imperfectionists"],
        playDate: "2023-10-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "NO EXIT",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1355577885025511",
        organizerIds: ["nepripraveni"],
        playDate: "2023-10-15",
        district: "Havlíčkův Brod",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Group Mind (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/297924842947532",
        organizerIds: [],
        playDate: "2023-10-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Jam! (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/1078836386435775",
        organizerIds: [],
        playDate: "2023-10-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro Talk Show: Ondřej Pšenička",
        eventType: "play",
        websiteUrl: "https://goout.net/cs/321jedem-ondrej-psenicka/szqrqnw/",
        organizerIds: ["321jedem"],
        playDate: "2023-10-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Ale to jsem měl říct já!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1010308070211522",
        organizerIds: ["polocasnapadu"],
        playDate: "2023-10-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizovaný Shakepeare",
        eventType: "play",
        websiteUrl:
          "https://nod.roxy.cz/events/detail/857/improvizovany-shakespeare-ceske-narodni-improvizacni-divadlo/2023-10-18",
        organizerIds: ["cnid"],
        playDate: "2023-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Jednorázový kurz improvizace pro páry",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/260559210145728",
        organizerIds: ["paletaci"],
        playDate: "2023-10-18",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "O nás dvou",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1341571186778253",
        organizerIds: ["bafni"],
        playDate: "2023-10-18",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kolo štěstí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1386143171937814",
        organizerIds: ["kopr"],
        playDate: "2023-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Lilie a karbanátky",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/698344405472296",
        organizerIds: ["improsamevody"],
        playDate: "2023-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Středeční otevřené impro",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1112068606427676",
        organizerIds: ["improdva"],
        playDate: "2023-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/334720042557433",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-10-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divadelní improvizace v dramatické výchově",
        eventType: "workshop",
        websiteUrl:
          "https://zivapedagogika.cz/kurzy-new/22-divadelni-improvizace-v-dramaticke-vychove/",
        organizerIds: ["zivapedagogika"],
        playDate: "2023-10-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Thursday Night Improv Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/621985480107410/629142102725081/",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-10-19",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Čtvrteční otevřené svobodné impro",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1061137141897142",
        organizerIds: ["improdva"],
        playDate: "2023-10-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "KIŠ vs. Poločas nápadu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/846348060407877",
        organizerIds: ["kis", "polocasnapadu"],
        playDate: "2023-10-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Workshop improvizce a la Meisner",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/218368954029556",
        organizerIds: [],
        playDate: "2023-10-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow jak z časáku",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/689964533186868",
        organizerIds: ["vip"],
        playDate: "2023-10-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizace k Osobnostnímu rozvoji",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/287863290587422",
        organizerIds: ["skola21"],
        playDate: "2023-10-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improkabaret: Vepřo, knedlo, impro!",
        eventType: "play",
        websiteUrl: "https://www.padenapade.com/predstaveni",
        organizerIds: ["padenapade"],
        playDate: "2023-10-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvystoupení: V.T.E.Ř.I.N.K.Y.",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1250468768912080",
        organizerIds: [],
        playDate: "2023-10-23",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "DOVOPRAVDY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1248070365886922",
        organizerIds: ["nedivse"],
        playDate: "2023-10-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Asterix a Obelix",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/150645018070349",
        organizerIds: ["improvariace"],
        playDate: "2023-10-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Czech + Expat Impro Show 🇨🇿🇺🇸🇨🇦 (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/803969901527157",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-10-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Thursday Night Armandoness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3228920547402203/",
        organizerIds: ["imperfectionists"],
        playDate: "2023-10-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Autentická improvizace",
        eventType: "workshop",
        websiteUrl: "https://improvizacnikurzy.cz/kurzy/vikend-brno-zacatecnici/",
        organizerIds: ["bafni"],
        playDate: "2023-10-27",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Workshop - The heart of storytelling (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1053922945621089",
        organizerIds: [],
        playDate: "2023-10-29",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Just! Impro Show",
        eventType: "play",
        websiteUrl: "https://www.svandovodivadlo.cz/inscenace/13/just-impro-just-impro-show/4802",
        organizerIds: ["justimpro"],
        playDate: "2023-10-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Listopad 2023",
    monthDate: "2023-11",
    events: [
      {
        id: "1",
        slugExtra: "",
        name: "We're back baby",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/818762473264270",
        organizerIds: ["minimpromalismus"],
        playDate: "2023-11-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizační divadelní představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1069376197297414",
        organizerIds: ["necijehneci"],
        playDate: "2023-11-02",
        district: "Říčany",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizované příběhy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/306704408756710",
        organizerIds: ["longformy"],
        playDate: "2023-11-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Benefiční improvizace pro Šanci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/655885783002944",
        organizerIds: [],
        playDate: "2023-11-02",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Víkendový workshop pro pokročilé",
        eventType: "workshop",
        websiteUrl: "https://improvizacnikurzy.cz/kurzy/vikendovy-workshop-praha-pokrocili/",
        organizerIds: ["bafni"],
        playDate: "2023-11-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizační zápas VE.ŠK.I. vs. Meandry",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/715154160028830",
        organizerIds: ["veski", "meandry"],
        playDate: "2023-11-03",
        district: "České Budějovice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Theatropolis JAM vol.3",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1382233266059381",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-11-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Imjoy and shakespearek",
        eventType: "play",
        websiteUrl: "https://www.naivnidivadlo.cz/cs/aktuality/vecer-v-naivnim-imjoy-shakespearek",
        organizerIds: ["imjoy"],
        playDate: "2023-11-04",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Valouny vs. Lísky",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1320182122035774",
        organizerIds: ["valouny", "lisky"],
        playDate: "2023-11-04",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kostlivci v županu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/702273835098455",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-11-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Jednohubky - jednorázový ochutnávkovo trénink",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/4150570501836301",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-11-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "(U)Zavření",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1412043499375551",
        organizerIds: ["divadlo301"],
        playDate: "2023-11-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro Kecky a dva úžasňáci - Adam Vošvrda a Lukáš Rumlena",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1006931377020100",
        organizerIds: ["kecky"],
        playDate: "2023-11-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "ImproZÁKUSKY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1462667047903268",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-11-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "All you can eat",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/807007527776661",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-11-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "NO EXIT",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/118087244720185",
        organizerIds: ["nepripraveni"],
        playDate: "2023-11-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "La Ronde (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3604539523096884",
        organizerIds: ["imperfectionists"],
        playDate: "2023-11-17",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Jsem, tedy IMPROVIZUJI ... IMPROVIZUJI, tedy jsem",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/870911031070415",
        organizerIds: ["katerinaburesova"],
        playDate: "2023-11-17",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Live Improv Comedy (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/305148915558364/305148918891697",
        organizerIds: ["scriptforbrains"],
        playDate: "2023-11-17",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Workshop - We're telling a story (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1380396762552000",
        organizerIds: [],
        playDate: "2023-11-19",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Nebezpečné vztahy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/971386207293374",
        organizerIds: ["impra"],
        playDate: "2023-11-20",
        district: "Šumperk",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Z nuly na pade - z pade na sto",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/658283676286785/",
        organizerIds: ["padenapade"],
        playDate: "2023-11-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Tarantino (18+)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1354575935142855",
        organizerIds: ["allimp"],
        playDate: "2023-11-21",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "(Listo)padá",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3632309460372695",
        organizerIds: ["kis"],
        playDate: "2023-11-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizující terapeut",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/665433372048184",
        organizerIds: [],
        playDate: "2023-11-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Palmaskán",
        eventType: "play",
        websiteUrl: "http://bafni.cz/repertoar/palmaskan.html",
        organizerIds: ["bafni"],
        playDate: "2023-11-22",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Just! Impro Show",
        eventType: "play",
        websiteUrl: "https://www.svandovodivadlo.cz/inscenace/13/just-impro-just-impro-show/4873",
        organizerIds: ["justimpro"],
        playDate: "2023-11-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Afterparty - premiéra roku",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/6509509712494413",
        organizerIds: ["paletaci"],
        playDate: "2023-11-22",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divadelní improvizace",
        eventType: "workshop",
        websiteUrl:
          "https://zivapedagogika.cz/kurzy-new/23-divadelni-improvizace-pro-ucitele-a-nejen-pro-ne/",
        organizerIds: ["zivapedagogika"],
        playDate: "2023-11-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Nereality show",
        eventType: "play",
        websiteUrl:
          "https://nod.roxy.cz/events/detail/715/nereality-show-ceske-narodni-improvizacni-divadlo/2023-11-23",
        organizerIds: ["cnid"],
        playDate: "2023-11-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizace na vaše témata",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1005271764037105",
        organizerIds: [],
        playDate: "2023-11-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizace pro rodiče a děti",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/224410933686595",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-11-24",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Olivy vs. olomoučtí herci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/6751710264910913",
        organizerIds: ["olivy"],
        playDate: "2023-11-24",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Longformy #2",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1016693512954012",
        organizerIds: ["lisky"],
        playDate: "2023-11-24",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impro poradna - Kam se schovat před bouří",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/956026075471081",
        organizerIds: ["nevyzadanarada"],
        playDate: "2023-11-25",
        district: "Karlovy Vary",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Gaučink na Zábradku",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1959293611121647",
        organizerIds: ["instantnigaucink"],
        playDate: "2023-11-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Jam! (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/694679005954663",
        organizerIds: [],
        playDate: "2023-11-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Storytime! (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/676263127980050",
        organizerIds: [],
        playDate: "2023-11-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Kabinetníci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/868531091318522/",
        organizerIds: [],
        playDate: "2023-11-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Minikurz improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/250011041223745",
        organizerIds: ["divadloprocity"],
        playDate: "2023-11-28",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Beauty IMPROspeciál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/314400424545084",
        organizerIds: ["improvariace"],
        playDate: "2023-11-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improkruháč - Otevřený trénink improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.vimcochci.cz/akce/improkruhac/2023-11-29/",
        organizerIds: [],
        playDate: "2023-11-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Let's Get Personal (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/753355769963109",
        organizerIds: ["imperfectionists"],
        playDate: "2023-11-30",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/243756038675125",
        organizerIds: ["blancinybileboty"],
        playDate: "2023-11-30",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improshow a la gastrozážitek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1391933095053356",
        organizerIds: ["dortvkapse"],
        playDate: "2023-11-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Prosinec 2023",
    monthDate: "2023-12",
    events: [
      //   {
      //     id: "1",
      //     slugExtra: "",
      //     name: "Vánoční improshow",
      //     eventType: "play",
      //     websiteUrl: "",
      //     organizer: "bafni",
      //     playDate: "2023-12-20",
      //     district: "Brno",
      //     language: "cs",

      //   },
      {
        id: "1",
        slugExtra: "",
        name: "Besídka s Alibi",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/361509152892169",
        organizerIds: ["alibiprochyby"],
        playDate: "2023-12-01",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "1",
        name: "Impro Ambasadors - How to do nothing with Ollie Rasini",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/850816756761585",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-12-02",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "2",
        name: "Impro Ambasadors - Spotaneous Dialogues (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/697161792041837",
        organizerIds: ["skolaimprovizace"],
        playDate: "2023-12-02",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Valouny vs. Olivy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/209953402142915",
        organizerIds: ["valouny", "olivy"],
        playDate: "2023-12-02",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vánoční improshow",
        eventType: "play",
        websiteUrl: "https://nepripraveni.cz/akce/vanocni-improshow-skupiny-nepripraveni/",
        organizerIds: ["nepripraveni"],
        playDate: "2023-12-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vosto5: Stand'artní kabaret",
        eventType: "play",
        websiteUrl: "https://www.dko.cz/index.php?menu=1416",
        organizerIds: ["standartnikabaret"],
        playDate: "2023-12-04",
        district: "Jihlava",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Podruhé na scéně!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1015680102932687",
        organizerIds: [],
        playDate: "2023-12-05",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Impronadílka aneb co čert (ne)chtěl",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1054991418876096",
        organizerIds: [],
        playDate: "2023-12-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Zase v Praze",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/6947546271935307",
        organizerIds: ["bafni"],
        playDate: "2023-12-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Peklo v županu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1569901460442386",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-12-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Básně a jak na ně",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/706636494727538",
        organizerIds: ["paletaci"],
        playDate: "2023-12-07",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "1",
        name: "Workshop - Christmas open class (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1035389531077892",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-12-07",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "2",
        name: "Jam Show - Christmas edition (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/291489643303194",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-12-07",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "ImproZÁKUSKY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3535680366650308",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-12-08",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Lísky vs. Valouny",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2193212867537475",
        organizerIds: ["lisky", "valouny"],
        playDate: "2023-12-08",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Velká improvizační show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/641380994827142",
        organizerIds: ["divadlokellari"],
        playDate: "2023-12-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improv Workshop - Game of the Scene (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1063052484887632",
        organizerIds: [],
        playDate: "2023-12-10",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vánoční improshow",
        eventType: "play",
        websiteUrl:
          "https://nepripraveni.cz/akce/vanocni-improshow-skupiny-nepripraveni-a-hostu-l-o-s/",
        organizerIds: ["nepripraveni"],
        playDate: "2023-12-10",
        district: "Havlíčkův Brod",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Minikurz improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/239821678966933",
        organizerIds: ["divadloprocity"],
        playDate: "2023-12-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Benefiční improshow pro Šťastný zvěřinec",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/726707852638566",
        organizerIds: ["bizoni"],
        playDate: "2023-12-12",
        district: "Hradec Králové",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Veselé Vánočky",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/353326120421278/",
        organizerIds: ["divadlo301"],
        playDate: "2023-12-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Mistr improvizace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/687695440123821",
        organizerIds: ["paletaci"],
        playDate: "2023-12-13",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Konkurz",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/666002865648186",
        organizerIds: ["altf4"],
        playDate: "2023-12-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "1",
        name: "Last open class (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/2462703523921508",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-12-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "2",
        name: "Jam Show (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/405061758511120",
        organizerIds: ["pragueimprovnetwork"],
        playDate: "2023-12-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Live Improv Comedy (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/305148915558364/305148918891697",
        organizerIds: ["scriptforbrains"],
        playDate: "2023-12-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Improvizace pro rodiče a děti",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/705398584804019",
        organizerIds: ["michaelapuchalka"],
        playDate: "2023-12-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vyšetřování inspektora Parkera - improvizovaná detektivka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1065419444699949",
        organizerIds: ["vip"],
        playDate: "2023-12-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Workshop improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1208669466508636/1211616099547306",
        organizerIds: ["kalegro"],
        playDate: "2023-12-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "A Better Place: A Special Christmas (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1042767920254226",
        organizerIds: ["imperfectionists"],
        playDate: "2023-12-16",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vánoční přání",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3655282648130635",
        organizerIds: ["padenapade"],
        playDate: "2023-12-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Tři župani pro Popelku",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1365681944341029",
        organizerIds: ["fofrvzupanu"],
        playDate: "2023-12-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Gondoly a hausbóty",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/344673694582402",
        organizerIds: ["improsamevody"],
        playDate: "2023-12-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Divoké Vánoce",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/649668413912672",
        organizerIds: ["bafni"],
        playDate: "2023-12-20",
        district: "Brno",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Vánoční IMPROspeciál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1261187124474129",
        organizerIds: ["improvariace"],
        playDate: "2023-12-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Just! Impro Show",
        eventType: "play",
        websiteUrl: "https://www.svandovodivadlo.cz/inscenace/13/just-impro-just-impro-show/4998",
        organizerIds: ["justimpro"],
        playDate: "2023-12-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Leden",
    monthDate: "2024-01",
    events: [
      {
        id: "e626300096360534@facebook.com",
        slugExtra: "",
        name: "Workshop pro rodiče a děti",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/626300096360534/",
        organizerIds: ["kecky"],
        playDate: "2024-01-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e987337512304757@facebook.com",
        slugExtra: "",
        name: "Víkendprov - víkendový impro seminář",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/987337512304757/",
        organizerIds: ["divadloprocity"],
        playDate: "2024-01-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1455698238625930@facebook.com",
        slugExtra: "",
        name: "Novoroční",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1455698238625930/",
        organizerIds: ["divadlo301"],
        playDate: "2024-01-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e364580192609311@facebook.com",
        slugExtra: "",
        name: "IMPROSHOW: OSTROV PŘEŽITÍ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/364580192609311/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2024-01-10",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e1073749913864837@facebook.com",
        slugExtra: "",
        name: "The Imperfectionists present Single Location Montageness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1073749913864837/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-01-13",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1725537844581999@facebook.com",
        slugExtra: "1",
        name: "Improv Workshop - Following the Story! (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1725537844581999/",
        organizerIds: ["nickford"],
        playDate: "2024-01-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "2",
        name: "Improv Jam (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/1064318417938857",
        organizerIds: ["nickford"],
        playDate: "2024-01-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Mrkev nebo Konina",
        eventType: "play",
        websiteUrl: "http://bafni.cz/index.php#row2",
        organizerIds: ["bafni"],
        playDate: "2024-01-17",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1084664145998017@facebook.com",
        slugExtra: "",
        name: "Otevřený trénink improvizace s Imjoy",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1084664145998017/",
        organizerIds: ["imjoy"],
        playDate: "2024-01-17",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e1220463342690257@facebook.com",
        slugExtra: "",
        name: "Konspirační IMPROspeciál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1220463342690257/",
        organizerIds: ["improvariace"],
        playDate: "2024-01-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e405383081826702@facebook.com",
        slugExtra: "",
        name: "Improvizujeme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/405383081826702/",
        organizerIds: ["improvizacecz"],
        playDate: "2024-01-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1983885348679611@facebook.com",
        slugExtra: "",
        name: "Studio Pade na Pade: Tři",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1983885348679611/",
        organizerIds: ["padenapade"],
        playDate: "2024-01-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e871984558041649@facebook.com",
        slugExtra: "",
        name: "ImproSURVIVAL aneb Turnaj šesti improvizátorů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/871984558041649/",
        organizerIds: ["vip"],
        playDate: "2024-01-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1701264356949732@facebook.com",
        slugExtra: "",
        name: "🧁 improZÁKUSKY představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1701264356949732/",
        organizerIds: ["michaelapuchalka", "katerinaburesova"],
        playDate: "2024-01-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e342324032020192@facebook.com",
        slugExtra: "",
        name: "Improzápas LÍSKY vs. LÍSKY ve Vážanech",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/342324032020192/",
        organizerIds: ["lisky"],
        playDate: "2024-01-19",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "e1542371933228940@facebook.com",
        slugExtra: "",
        name: "Hříšníci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1542371933228940/",
        organizerIds: ["therandoms"],
        playDate: "2024-01-24",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e690516519900338@facebook.com",
        slugExtra: "",
        name: "Let's Get Personal (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/690516519900338/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-01-25",
        district: "Praha",
        language: "en",
      },
      {
        id: "e390625939981914@facebook.com",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/390625939981914/",
        organizerIds: ["blancinybileboty"],
        playDate: "2024-01-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1528389764612024@facebook.com",
        slugExtra: "",
        name: "Improzápas FrMol vs. ImJoy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1528389764612024/",
        organizerIds: ["frmol", "imjoy"],
        playDate: "2024-01-27",
        district: "Frýdek-Místek",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Just! Impro Show",
        eventType: "play",
        websiteUrl: "https://www.svandovodivadlo.cz/inscenace/13/just-impro-show/5061",
        organizerIds: ["justimpro"],
        playDate: "2024-01-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1745371759265665@facebook.com",
        slugExtra: "",
        name: "Improshow Meandrů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1745371759265665/",
        organizerIds: ["meandry"],
        playDate: "2024-01-31",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e294666156398900@facebook.com",
        slugExtra: "",
        name: "IMPROVIZACE k Osobnostnímu rozvoji",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/294666156398900/",
        organizerIds: ["skola21"],
        playDate: "2024-01-31",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Únor",
    monthDate: "2024-02",
    events: [
      {
        id: "e756349989299687@facebook.com",
        slugExtra: "",
        name: "hra s kostrou - METSYS",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/756349989299687/",
        organizerIds: ["valouny"],
        playDate: "2024-02-03",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "e2371080643087819@facebook.com",
        slugExtra: "",
        name: "Počátek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2371080643087819/",
        organizerIds: ["divadlo301"],
        playDate: "2024-02-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1014133719677652@facebook.com",
        slugExtra: "",
        name: "𝘿eus 𝙀x Mikrofona",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1014133719677652/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-02-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e7149375768511183@facebook.com",
        slugExtra: "",
        name: "Únorový improvizační workshop Improvizace tělem",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/7149375768511183/",
        organizerIds: ["petraandrasik"],
        playDate: "2024-02-06",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1818122661973484@facebook.com",
        slugExtra: "",
        name: "Theatropolis JAM Vol. 4",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1818122661973484/",
        organizerIds: ["skolaimprovizace"],
        playDate: "2024-02-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e904361677592658@facebook.com",
        slugExtra: "",
        name: "Zápas v divadelní improvizaci NĚČÍ JEHNĚČÍ X BIZONI ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/904361677592658/",
        organizerIds: ["necijehneci", "bizoni"],
        playDate: "2024-02-08",
        district: "Říčany",
        language: "cs",
      },
      {
        id: "e358602446913242@facebook.com",
        slugExtra: "",
        name: "Improvizační Talkshow s Lukášem Smetanou",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/358602446913242/",
        organizerIds: ["paletaci"],
        playDate: "2024-02-08",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e394027309737793@facebook.com",
        slugExtra: "",
        name: "ROZLETY: Ukázková lekce",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/394027309737793/",
        organizerIds: ["petraandrasik"],
        playDate: "2024-02-08",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1326994691323483@facebook.com",
        slugExtra: "",
        name: "New Year - New IMPROV Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1326994691323483/",
        organizerIds: ["creativedifferences"],
        playDate: "2024-02-09",
        district: "Praha",
        language: "en",
      },
      {
        id: "e920473776156325@facebook.com",
        slugExtra: "",
        name: "KIŠ vs VIP zápas divadelní improvizace ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/920473776156325/",
        organizerIds: ["kis", "vip"],
        playDate: "2024-02-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e882240850024382@facebook.com",
        slugExtra: "",
        name: "Improbuchty",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/882240850024382/",
        organizerIds: ["olivy"],
        playDate: "2024-02-09",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "e887876966414326@facebook.com",
        slugExtra: "",
        name: "Jednodenní kurz: Nevymejšlej!",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/887876966414326/",
        organizerIds: ["paletaci"],
        playDate: "2024-02-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1090132938845930@facebook.com",
        slugExtra: "",
        name: "Improv Workshop - Let's Armando! (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1090132938845930/",
        organizerIds: ["nickford"],
        playDate: "2024-02-11",
        district: "Praha",
        language: "en",
      },
      {
        id: "e351729921068500@facebook.com",
        slugExtra: "",
        name: "Otevřená hodina improvizace - Pardubice",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/351729921068500/",
        organizerIds: ["paletaci"],
        playDate: "2024-02-12",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e639232281577551@facebook.com",
        slugExtra: "",
        name: "321jedem! host: Ivan Hlas ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/639232281577551/",
        organizerIds: ["321jedem"],
        playDate: "2024-02-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e409743874832867@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: V předvečer Valentýna",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/409743874832867/",
        organizerIds: ["therandoms"],
        playDate: "2024-02-13",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1422001872066293@facebook.com",
        slugExtra: "",
        name: "All Imp – Randíčko",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1422001872066293/",
        organizerIds: ["allimp"],
        playDate: "2024-02-13",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e888119782958386@facebook.com",
        slugExtra: "",
        name: "Alibi na Valentýna",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/888119782958386/",
        organizerIds: ["alibiprochyby"],
        playDate: "2024-02-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e693126376267197@facebook.com",
        slugExtra: "",
        name: "Valentýnský IMPROspeciál / IMPROvariace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/693126376267197/",
        organizerIds: ["improvariace"],
        playDate: "2024-02-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e698217495750265@facebook.com",
        slugExtra: "",
        name: "Improvizujeme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/698217495750265/",
        organizerIds: ["improvizacecz"],
        playDate: "2024-02-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1063120964894734@facebook.com",
        slugExtra: "",
        name: "Karel poprvé",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1063120964894734/",
        organizerIds: ["karel"],
        playDate: "2024-02-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e296950006731021@facebook.com",
        slugExtra: "",
        name: "Improvizované příběhy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/296950006731021/",
        organizerIds: ["longformy"],
        playDate: "2024-02-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e356760850634710@facebook.com",
        slugExtra: "",
        name: "Amorovy katastrofy - valentýnská improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/356760850634710/",
        organizerIds: ["vip"],
        playDate: "2024-02-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e6635625499874726@facebook.com",
        slugExtra: "",
        name: "Divadelní improvizace: K. Melíšková, J. Strahovský & DJP",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/6635625499874726/",
        organizerIds: [],
        playDate: "2024-02-16",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e2142858382724279@facebook.com",
        slugExtra: "",
        name: "MiNiMPRO v Apropo",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2142858382724279/",
        organizerIds: ["minimpromalismus"],
        playDate: "2024-02-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e899403514983587@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/899403501650255/?event_time_id=899403514983587",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-02-16",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1046643633061774@facebook.com",
        slugExtra: "",
        name: "A Night of Seven Sins (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1046643633061774/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-02-17",
        district: "Praha",
        language: "en",
      },
      {
        id: "e746993870638679@facebook.com",
        slugExtra: "",
        name: "Večer v Naivním: Imjoy & tu-dum, tu-dum",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/746993870638679/",
        organizerIds: ["imjoy"],
        playDate: "2024-02-17",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e379934527967001@facebook.com",
        slugExtra: "",
        name: "Improv Jam! (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/379934527967001/",
        organizerIds: ["nickford"],
        playDate: "2024-02-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Just! Impro Show",
        eventType: "play",
        websiteUrl: "https://www.svandovodivadlo.cz/inscenace/13/just-impro-show/5150",
        organizerIds: ["justimpro"],
        playDate: "2024-02-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2183706821964713@facebook.com",
        slugExtra: "",
        name: "Konspirační improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2183706821964713/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-02-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e374546371767009@facebook.com",
        slugExtra: "",
        name: "Jak to bylo",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/374546371767009/",
        organizerIds: ["bafni"],
        playDate: "2024-02-21",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1129377141829625@facebook.com",
        slugExtra: "",
        name: "Improshow: Blančiny bílé boty",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1129377141829625/",
        organizerIds: ["blancinybileboty"],
        playDate: "2024-02-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1608801542986847@facebook.com",
        slugExtra: "",
        name: "Grand Opening Improcentrum Vyšehradská 8",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1608801542986847/",
        organizerIds: ["skolaimprovizace"],
        playDate: "2024-02-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e902119924980686@facebook.com",
        slugExtra: "",
        name: "The Graduate Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/902119924980686/",
        organizerIds: ["blr"],
        playDate: "2024-02-23",
        district: "Praha",
        language: "en",
      },
      {
        id: "e393662203215279@facebook.com",
        slugExtra: "",
        name: "🧁 improZÁKUSKY představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/393662203215279/",
        organizerIds: ["katerinaburesova", "michaelapuchalka"],
        playDate: "2024-02-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1369106270381344@facebook.com",
        slugExtra: "",
        name: "Umění improvizace – Monika Nevolová | InspiroHub",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1369106270381344/",
        organizerIds: [],
        playDate: "2024-02-23",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e6965878346805796@facebook.com",
        slugExtra: "",
        name: "Improvizace (víkendový kurz)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/6965878346805796/",
        organizerIds: ["marianazacharova"],
        playDate: "2024-02-24",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1059648085270892@facebook.com",
        slugExtra: "",
        name: "Maličkosti a Middleditch & Schwartz",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1059648085270892/",
        organizerIds: ["frmol"],
        playDate: "2024-02-24",
        district: "Frýdek-Místek",
        language: "cs",
      },
      {
        id: "e359634976925320@facebook.com",
        slugExtra: "",
        name: "IMPROVIZOVANÝ SHAKESPEARE",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/359634976925320/",
        organizerIds: ["cnid"],
        playDate: "2024-02-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e726598652761638@facebook.com",
        slugExtra: "",
        name: "Terapie županem",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/726598652761638/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-02-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "",
        slugExtra: "",
        name: "The Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://improvacademy.cz/events-%26-shows",
        organizerIds: ["improvacademy"],
        playDate: "2024-02-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1095720104897812@facebook.com",
        slugExtra: "",
        name: "Kecky a Kája Bendová ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1095720104897812/",
        organizerIds: ["kecky"],
        playDate: "2024-02-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1113577369646520@facebook.com",
        slugExtra: "",
        name: "Budoucnost je teď (improvizovaná komedie s AI)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1113577369646520/",
        organizerIds: ["padenapade"],
        playDate: "2024-02-29",
        district: "Praha",
        language: "cs",
      },
      // {
      //   id: "e749411060429949@facebook.com",
      //   slugExtra: "",
      //   name: "Malé formy – cyklus storytellingu a divadelní improvizace",
      //   eventType: "workshop",
      //   websiteUrl: "https://www.facebook.com/events/749411060429949/",
      //   organizerIds: ["zivapedagogika"],
      //   playDate: "2024-02-29",
      //   district: "Praha",
      //   language: "cs",
      // },
    ],
  },
  {
    monthName: "Březen",
    monthDate: "2024-03",
    events: [
      {
        id: "e737620174911401@facebook.com",
        slugExtra: "",
        name: "👨‍👧IMPROVIZACE pro RODIČE&DĚTI",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/737620174911401/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-03-01",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2058769951156999@facebook.com",
        slugExtra: "",
        name: "VALOUNY vs. PALEŤÁCI",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2058769951156999/",
        organizerIds: ["valouny"],
        playDate: "2024-03-02",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "e3710991679118838@facebook.com",
        slugExtra: "",
        name: "Dort v Kapse - Láska imprem začíná",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3710991679118838/",
        organizerIds: ["dortvkapse"],
        playDate: "2024-03-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e754120499941421@facebook.com",
        slugExtra: "",
        name: "reprízy 🌟 𝘿𝙀𝙐𝙎 𝙀𝙓 𝙈𝙄𝙆𝙍𝙊𝙁𝙊𝙉𝘼 🌟",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/754120489941422/?event_time_id=754120499941421",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-03-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e803575691532642@facebook.com",
        slugExtra: "",
        name: "MAMI, TATI, KLUCI ŘÍKALI... pohádka na Jatka78",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/803575691532642/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-03-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1347877029235201@facebook.com",
        slugExtra: "",
        name: "Proti(vové)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1347877029235201/",
        organizerIds: ["divadlo301"],
        playDate: "2024-03-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1545272856236650@facebook.com",
        slugExtra: "",
        name: "NeDivSe: DOVOPRAVDY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1545272856236650/",
        organizerIds: ["nedivse"],
        playDate: "2024-03-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e856777312869425@facebook.com",
        slugExtra: "",
        name: "Otevřený trénink s Imjoy",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/856777312869425/",
        organizerIds: ["imjoy"],
        playDate: "2024-03-06",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e856385656291715@facebook.com",
        slugExtra: "",
        name: "Single Location Montageness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/856385656291715/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-03-07",
        district: "Praha",
        language: "en",
      },
      {
        id: "e891151626074291@facebook.com",
        slugExtra: "",
        name: "K.Op.R. vs Paleťáci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/891151626074291/",
        organizerIds: ["kopr", "paletaci"],
        playDate: "2024-03-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "",
        slugExtra: "",
        name: "The Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://improvacademy.cz/events-%26-shows",
        organizerIds: ["improvacademy"],
        playDate: "2024-03-11",
        district: "Praha",
        language: "en",
      },
      {
        id: "e382135931423786@facebook.com",
        slugExtra: "",
        name: "Studio Pade na Pade: Metafóry",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/382135931423786/",
        organizerIds: ["padenapade"],
        playDate: "2024-03-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1451759352069743@facebook.com",
        slugExtra: "",
        name: "All Imp – Hříšná",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1451759352069743/",
        organizerIds: ["allimp"],
        playDate: "2024-03-12",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e401145985642264@facebook.com",
        slugExtra: "",
        name: "Swifthood Hollywood",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/401145985642264/",
        organizerIds: ["marianazacharova"],
        playDate: "2024-03-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e708755201372037@facebook.com",
        slugExtra: "",
        name: "POMÁDA / celovečerní improvizovaná hra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/708755201372037/",
        organizerIds: ["improvariace"],
        playDate: "2024-03-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e899403528316919@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/899403501650255/?event_time_id=899403528316919",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-03-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "e774714651177046@facebook.com",
        slugExtra: "",
        name: "KIŠí Spektroskop ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/774714651177046/",
        organizerIds: ["kis"],
        playDate: "2024-03-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e818157127017808@facebook.com",
        slugExtra: "",
        name: "T.T.T. VEĎ IMPRO a NECH se VÉST III volné pokračování",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/818157127017808/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-03-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "",
        slugExtra: "",
        name: "The Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://improvacademy.cz/events-%26-shows",
        organizerIds: ["improvacademy"],
        playDate: "2024-03-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e25261991440058492@facebook.com",
        slugExtra: "",
        name: "Theatropolis JAM Vol. 5 (vstup dobrovolný 😉)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/25261991440058492/",
        organizerIds: ["skolaimprovizace"],
        playDate: "2024-03-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e791929946285918@facebook.com",
        slugExtra: "",
        name: "IMPRO BIJÁK - improshow inspirovaná filmy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/791929946285918/",
        organizerIds: ["vip"],
        playDate: "2024-03-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1446000019645346@facebook.com",
        slugExtra: "",
        name: "The Intermediate Class Graduate Show! (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1446000019645346/",
        organizerIds: ["blr"],
        playDate: "2024-03-22",
        district: "Praha",
        language: "en",
      },
      {
        id: "e948240646840370@facebook.com",
        slugExtra: "",
        name: "Paleťáci: Impro á la Tarantino",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/948240646840370/",
        organizerIds: ["paletaci"],
        playDate: "2024-03-22",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e368029646114822@facebook.com",
        slugExtra: "",
        name: "Víkend rétoriky a improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/368029646114822/",
        organizerIds: ["improinstitut"],
        playDate: "2024-03-22",
        district: "Litomyšl",
        language: "cs",
      },
      {
        id: "e1176432037063441@facebook.com",
        slugExtra: "",
        name: "La Ronde (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1176432037063441/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-03-23",
        district: "Praha",
        language: "en",
      },
      {
        id: "e408796744962173@facebook.com",
        slugExtra: "",
        name: "Celodenní kurz: Klaunem na jeden den",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/408796744962173/",
        organizerIds: ["paletaci"],
        playDate: "2024-03-23",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Historky ze vsi",
        eventType: "play",
        websiteUrl: "https://nepripraveni.cz/akce/historky-ze-vsi/",
        organizerIds: ["nepripraveni"],
        playDate: "2024-03-24",
        district: "Havlíčkův Brod",
        language: "cs",
      },

      {
        id: "e1534683917072717@facebook.com",
        slugExtra: "",
        name: "Fofrwood",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1534683917072717/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-03-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e7720037401343805@facebook.com",
        slugExtra: "",
        name: "321jedem! host: Ondřej Šedivka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7720037401343805/",
        organizerIds: ["321jedem"],
        playDate: "2024-03-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1065775117864464@facebook.com",
        slugExtra: "",
        name: "Barbie vs Oppenheimer | Pade na Pade v Rock Café",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1065775117864464/",
        organizerIds: ["padenapade"],
        playDate: "2024-03-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e261350033625189@facebook.com",
        slugExtra: "",
        name: "Bafni The Musical",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/261350033625189/",
        organizerIds: ["bafni"],
        playDate: "2024-03-27",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e907944277639459@facebook.com",
        slugExtra: "",
        name: "SERIÁL / SEASON 3 / České národní improvizační divadlo",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/907944260972794/?event_time_id=907944277639459",
        organizerIds: ["cnid"],
        playDate: "2024-03-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1052982515764395@facebook.com",
        slugExtra: "",
        name: "Tajemství Vesmíru (Rozpletená Zápletka)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1052982515764395/",
        organizerIds: ["saysomethingcz"],
        playDate: "2024-03-27",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Duben",
    monthDate: "2024-04",
    events: [
      {
        id: "e329078436808292@facebook.com",
        slugExtra: "",
        name: "Návrat protivů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/329078436808292/",
        organizerIds: ["divadlo301"],
        playDate: "2024-04-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e895274942131058@facebook.com",
        slugExtra: "",
        name: "Improvizační show divadla Bafni v Praze",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/895274942131058/",
        organizerIds: ["bafni"],
        playDate: "2024-04-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1097319741603115@facebook.com",
        slugExtra: "",
        name: "Lovestorky - Improshow ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1097319741603115/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-04-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e868755415028893@facebook.com",
        slugExtra: "",
        name: "Hlas, zpěv a hudba v improvizaci",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/868755415028893/",
        organizerIds: ["bafni"],
        playDate: "2024-04-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e848555950401857@facebook.com",
        slugExtra: "",
        name: "GILTY PLEŽR: HOROR / ČNID & IMPROvariace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/848555950401857/",
        organizerIds: ["cnid", "improvariace"],
        playDate: "2024-04-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e375134392120541@facebook.com",
        slugExtra: "",
        name: "TARANTINO",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/375134392120541/",
        organizerIds: ["zadvermi"],
        playDate: "2024-04-06",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "e792118318935115@facebook.com",
        slugExtra: "",
        name: "VALOUNY - NOVÁ KREV",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/792118318935115/",
        organizerIds: ["valouny"],
        playDate: "2024-04-06",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "e1073667797046610@facebook.com",
        slugExtra: "",
        name: "Instantní Gaučink - 7. 4. 2024",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1073667797046610/",
        organizerIds: ["instantnigaucink"],
        playDate: "2024-04-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e333196859753421@facebook.com",
        slugExtra: "",
        name: "V4 Improv Connect",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/333196859753421/",
        organizerIds: [],
        playDate: "2024-04-09",
        district: "Bratislava",
        language: "cs",
      },
      {
        id: "e826793492624404@facebook.com",
        slugExtra: "",
        name: "Improvizujeme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/826793492624404/",
        organizerIds: ["improvizacecz"],
        playDate: "2024-04-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1632130000890319@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: Hříšníci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1632130000890319/",
        organizerIds: ["therandoms"],
        playDate: "2024-04-10",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e385733547558781@facebook.com",
        slugExtra: "",
        name: "ALT+F4: Dezinformátoři",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/385733547558781/",
        organizerIds: ["altf4"],
        playDate: "2024-04-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1629757191187679@facebook.com",
        slugExtra: "",
        name: "Improv Comedy Show at Meeples (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1629757181187680/?event_time_id=1629757191187679",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-04-11",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1321896308479206@facebook.com",
        slugExtra: "",
        name: "👩‍👦IMPROVIZACE pro RODIČE&DĚTI",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1321896308479206/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-04-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1585995025512152@facebook.com",
        slugExtra: "",
        name: "Paleťáci: Show splněných přání",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1585995025512152/",
        organizerIds: ["paletaci"],
        playDate: "2024-04-13",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e434966345636708@facebook.com",
        slugExtra: "",
        name: "Let's Get Personal (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/434966345636708/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-04-13",
        district: "Praha",
        language: "en",
      },
      {
        id: "e907944280972792@facebook.com",
        slugExtra: "",
        name: "SERIÁL / SEASON 3 / České národní improvizační divadlo",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/907944260972794/?event_time_id=907944280972792",
        organizerIds: ["cnid"],
        playDate: "2024-04-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e821718839760294@facebook.com",
        slugExtra: "1",
        name: "Improv Workshop - We're in this together (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/821718839760294/",
        organizerIds: ["nickford"],
        playDate: "2024-04-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "e337663902194698@facebook.com",
        slugExtra: "2",
        name: "Improv Jam! (EN)",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/337663902194698/",
        organizerIds: ["nickford"],
        playDate: "2024-04-14",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1417180749169211@facebook.com",
        slugExtra: "",
        name: "All Imp vs. Vývar",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1417180749169211/",
        organizerIds: ["allimp"],
        playDate: "2024-04-16",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e720017590243571@facebook.com",
        slugExtra: "",
        name: "Mnoho povyku impro nic",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/720017590243571/",
        organizerIds: ["sis"],
        playDate: "2024-04-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e925016452745531@facebook.com",
        slugExtra: "",
        name: "Podfukářský IMPROspeciál / IMPROvariace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/925016452745531/",
        organizerIds: ["improvariace"],
        playDate: "2024-04-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e316663091270123@facebook.com",
        slugExtra: "",
        name: "Bakaláři",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/316663091270123/",
        organizerIds: ["bafni"],
        playDate: "2024-04-18",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1044519363465157@facebook.com",
        slugExtra: "",
        name: "::Taking Space & Giving Time::Sten Rudstrøm & !Action Theater Prague!::",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1044519363465157/",
        organizerIds: [],
        playDate: "2024-04-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1096395694886958@facebook.com",
        slugExtra: "",
        name: "🧁 improZÁKUSKY představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1096395694886958/",
        organizerIds: ["katerinaburesova"],
        playDate: "2024-04-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e899403518316920@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/899403501650255/?event_time_id=899403518316920",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-04-19",
        district: "Praha",
        language: "en",
      },
      {
        id: "e943929647395636@facebook.com",
        slugExtra: "",
        name: "Aprílové Meandry v Music Labu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/943929647395636/",
        organizerIds: ["meandry"],
        playDate: "2024-04-19",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e292749883863208@facebook.com",
        slugExtra: "",
        name: "Vyšetřování inspektora Parkera - improvizovaná detektivka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/292749883863208/",
        organizerIds: ["vip"],
        playDate: "2024-04-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2688702321289666@facebook.com",
        slugExtra: "",
        name: "LONGFORMY #3",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2688702321289666/",
        organizerIds: ["lisky"],
        playDate: "2024-04-20",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "e3736740413248109@facebook.com",
        slugExtra: "",
        name: "IMPROSHOW | Leze na vás jaro?",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3736740413248109/",
        organizerIds: ["nepripraveni"],
        playDate: "2024-04-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e3665755040349257@facebook.com",
        slugExtra: "",
        name: "Jak si přejete! Improvizovaná činohra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3665755040349257/",
        organizerIds: ["padenapade"],
        playDate: "2024-04-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1819789011819631@facebook.com",
        slugExtra: "",
        name: "321jedem! host: Ondřej Synek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1819789011819631/",
        organizerIds: ["321jedem"],
        playDate: "2024-04-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1629757197854345@facebook.com",
        slugExtra: "",
        name: "Improv Comedy Show at Meeples (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1629757181187680/?event_time_id=1629757197854345",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-04-25",
        district: "Praha",
        language: "en",
      },
      {
        id: "e938264554243270@facebook.com",
        slugExtra: "",
        name: "Olivy vs. Lísky (Boskovice) - improvizační zápas",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/938264554243270/",
        organizerIds: ["olivy"],
        playDate: "2024-04-26",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "e1118248672692965@facebook.com",
        slugExtra: "",
        name: "YU aneb DIVADLO SKONŠTRUOVANÉHO VZRUŠENIA a 3000 otáček: Kompozice okamžiku",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1118248672692965/",
        organizerIds: [],
        playDate: "2024-04-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1786590175142226@facebook.com",
        slugExtra: "",
        name: "improvizovaný muzikál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1786590175142226/",
        organizerIds: ["kis"],
        playDate: "2024-04-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1624761581262476@facebook.com",
        slugExtra: "",
        name: "Hvězdění",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1624761581262476/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-04-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e959294175339319@facebook.com",
        slugExtra: "",
        name: "Friday Night Armandoness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/959294175339319/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-04-26",
        district: "Praha",
        language: "en",
      },
      {
        id: "e907944270972793@facebook.com",
        slugExtra: "",
        name: "SERIÁL / SEASON 3 / České národní improvizační divadlo",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/907944260972794/?event_time_id=907944270972793",
        organizerIds: ["cnid"],
        playDate: "2024-04-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e3263037690669954@facebook.com",
        slugExtra: "",
        name: "NeDivSe: DOVOPRAVDY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3263037690669954/",
        organizerIds: ["nedivse"],
        playDate: "2024-04-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e7405275966171187@facebook.com",
        slugExtra: "",
        name: "Královna And v Rock Cafe",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7405275966171187/",
        organizerIds: ["divadloprocity"],
        playDate: "2024-04-29",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Květen",
    monthDate: "2024-05",
    events: [
      {
        id: "e377958598385237@facebook.com",
        slugExtra: "",
        name: "Oslavy 15 let: Pojď si zaimprovizovat",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/377958598385237/",
        organizerIds: ["paletaci"],
        playDate: "2024-05-01",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e1353577382025305@facebook.com",
        slugExtra: "",
        name: "Workshop with Jonathan Pitts (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1353577382025305/",
        organizerIds: [],
        playDate: "2024-05-02",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1662837327580688@facebook.com",
        slugExtra: "",
        name: "Improvizační workshop pro začátečníky v Praze",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1662837327580688/",
        organizerIds: ["bafni"],
        playDate: "2024-05-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2065771183794040@facebook.com",
        slugExtra: "",
        name: "Herecký a improvizační workshop: Stereotypy",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/2065771183794040/",
        organizerIds: [],
        playDate: "2024-05-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e982567546566785@facebook.com",
        slugExtra: "",
        name: "Impro speciál s Jonathanem (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/982567546566785/",
        organizerIds: [],
        playDate: "2024-05-05",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1113584443231453@facebook.com",
        slugExtra: "",
        name: "Olivová show na Olomouckém majálesu ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1113584443231453/",
        organizerIds: ["olivy"],
        playDate: "2024-05-06",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "e773801611132270@facebook.com",
        slugExtra: "",
        name: "Sbal si kufry!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/773801611132270/",
        organizerIds: ["divadlo301"],
        playDate: "2024-05-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1547721322753342@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1547721316086676/?event_time_id=1547721322753342",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-05-09",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1076859186845586@facebook.com",
        slugExtra: "",
        name: "Víkendový workshop improvizace v Brně",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1076859186845586/",
        organizerIds: ["bafni"],
        playDate: "2024-05-10",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e735555732017028@facebook.com",
        slugExtra: "",
        name: "Impro Pro víkend",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/735555732017028/",
        organizerIds: ["howtoflow"],
        playDate: "2024-05-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e6827212790711980@facebook.com",
        slugExtra: "",
        name: "Just!Impro show ve Švanďáku",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/6827212780711981/?event_time_id=6827212790711980",
        organizerIds: ["justimpro"],
        playDate: "2024-05-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e293830633744086@facebook.com",
        slugExtra: "",
        name: "🥗ALL YOU CAN EAT impro ws",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/293830633744086/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-05-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e343089205432379@facebook.com",
        slugExtra: "",
        name: "V4 Improv Connect: The Big Show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/343089205432379/",
        organizerIds: [],
        playDate: "2024-05-11",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1368123177090393@facebook.com",
        slugExtra: "",
        name: "Improv Workshop - Listen Before You Speak",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1368123177090393/",
        organizerIds: [],
        playDate: "2024-05-12",
        district: "Praha",
        language: "en",
      },
      {
        id: "e740782921215249@facebook.com",
        slugExtra: "",
        name: "321jedem! host: Jindra Čapek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/740782921215249/",
        organizerIds: ["321jedem"],
        playDate: "2024-05-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e428568189817052@facebook.com",
        slugExtra: "",
        name: "NeDivSe - DOVOPRAVDY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/428568189817052/",
        organizerIds: ["nedivse"],
        playDate: "2024-05-13",
        district: "Dobříš",
        language: "cs",
      },
      {
        id: "e884638656371860@facebook.com",
        slugExtra: "",
        name: "Jsme přátelé",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/884638656371860/",
        organizerIds: ["bafni"],
        playDate: "2024-05-15",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1501771957080546@facebook.com",
        slugExtra: "",
        name: "Improvizujeme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1501771957080546/",
        organizerIds: ["improvizacecz"],
        playDate: "2024-05-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1438006740452057@facebook.com",
        slugExtra: "",
        name: "Ukázková lekce improvizace ZDARMA",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1438006740452057/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-05-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e354587550552969@facebook.com",
        slugExtra: "",
        name: "👨‍👩‍👧‍👦IMPROVIZACE pro RODIČE&DĚTI",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/354587550552969/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-05-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e458059323419987@facebook.com",
        slugExtra: "",
        name: "ZÁPAS divadelní improvizace Poločas nápadu vs Olivy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/458059323419987/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-05-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e938453764743153@facebook.com",
        slugExtra: "",
        name: "SPORTU ZDAR! aneb improshow o sportu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/938453764743153/",
        organizerIds: ["vip"],
        playDate: "2024-05-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e899403521650253@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/899403501650255/?event_time_id=899403521650253",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-05-17",
        district: "Praha",
        language: "en",
      },
      {
        id: "e213848738387123@facebook.com",
        slugExtra: "",
        name: "Víkendový workshop: Základy improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/213848738387123/",
        organizerIds: ["paletaci"],
        playDate: "2024-05-17",
        district: "Středočeský kraj",
        language: "cs",
      },
      {
        id: "e1656227481784309@facebook.com",
        slugExtra: "",
        name: "By the Book (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1656227481784309/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-05-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1109866816979431@facebook.com",
        slugExtra: "",
        name: "Creating characters: A treasure trove of options",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1109866816979431/",
        organizerIds: [],
        playDate: "2024-05-19",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1873125913113736@facebook.com",
        slugExtra: "",
        name: "Hledání mystéria improvizace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1873125913113736/",
        organizerIds: ["improvize"],
        playDate: "2024-05-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e907944267639460@facebook.com",
        slugExtra: "",
        name: "SERIÁL / SEASON 3 / České národní improvizační divadlo",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/907944260972794/?event_time_id=907944267639460",
        organizerIds: ["cnid"],
        playDate: "2024-05-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e992983585729715@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: Na rande!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/992983585729715/",
        organizerIds: ["therandoms"],
        playDate: "2024-05-22",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e7952038681496180@facebook.com",
        slugExtra: "",
        name: "Zápas v divadelní improvizaci: Čechovova fuška versus něčí jehněčí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7952038681496180/",
        organizerIds: ["cechovovafuska", "necijehneci"],
        playDate: "2024-05-23",
        district: "Říčany",
        language: "cs",
      },
      {
        id: "e2091475737876545@facebook.com",
        slugExtra: "",
        name: "Cirkus Lynch",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2091475737876545/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-05-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1547721326086675@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1547721316086676/?event_time_id=1547721326086675",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-05-23",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1392802875450852@facebook.com",
        slugExtra: "",
        name: "Otevřený trénink Improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1392802875450852/",
        organizerIds: ["imjoy"],
        playDate: "2024-05-23",
        district: "Liberec",
        language: "cs",
      },
      // {
      //   id: "e1484579442390708@facebook.com",
      //   slugExtra: "",
      //   name: "Improvizace pro život - víkend",
      //   eventType: "workshop",
      //   websiteUrl: "https://www.facebook.com/events/1484579442390708/",
      //   organizerIds: ["monikanevolova"],
      //   playDate: "2024-05-24",
      //   district: "Havlíčkův Brod",
      //   language: "cs",
      // },
      {
        id: "e758592116095287@facebook.com",
        slugExtra: "",
        name: "Improshow Festival",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/758592116095287/",
        organizerIds: ["vyvar"],
        playDate: "2024-05-28",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e420585104051017@facebook.com",
        slugExtra: "",
        name: "Letní Improfest 2024",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/420585104051017/",
        organizerIds: ["skolaimprovizace"],
        playDate: "2024-05-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e405810885715085@facebook.com",
        slugExtra: "",
        name: "Improshow: Blančiny bílé boty",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/405810885715085/",
        organizerIds: ["blancinybileboty"],
        playDate: "2024-05-30",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1011029343948140@facebook.com",
        slugExtra: "",
        name: "Single Location Montageness (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1011029343948140/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-05-30",
        district: "Praha",
        language: "en",
      },
      {
        id: "e429504282809472@facebook.com",
        slugExtra: "",
        name: "IMPROTŘESK 2024 | Festival divadelní improvizace",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/429504282809472/",
        organizerIds: ["divadloimprovizaceodvaz"],
        playDate: "2024-05-31",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e353353317204300@facebook.com",
        slugExtra: "",
        name: "Improshow Meandrů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/353353317204300/",
        organizerIds: ["meandry"],
        playDate: "2024-05-31",
        district: "Brno",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Červen",
    monthDate: "2024-06",
    events: [
      {
        id: "e326060000525209@facebook.com",
        slugExtra: "",
        name: "Impro ohňostroj",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/326060000525209/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-06-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1094471655005354@facebook.com",
        slugExtra: "",
        name: "Autentická prezentace - jak prezentují improvizátoři",
        eventType: "workshop",
        websiteUrl: "https://www.kabinetimprovizace.cz",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-06-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1131907011361399@facebook.com",
        slugExtra: "",
        name: "Dovolená",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1131907011361399/",
        organizerIds: ["divadlo301"],
        playDate: "2024-06-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1094471655005354@facebook.com",
        slugExtra: "",
        name: "Bleskové impro - základy v jednom dopoledni",
        eventType: "workshop",
        websiteUrl: "https://www.kabinetimprovizace.cz",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-06-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e7336825463038530@facebook.com",
        slugExtra: "",
        name: "Gaučink na Zábradku - 9. 6. 2024",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7336825463038530/",
        organizerIds: ["instantnigaucink"],
        playDate: "2024-06-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e449707507448777@facebook.com",
        slugExtra: "",
        name: "Re-use dny u Zauhlovačky 9.6.",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/449707507448777/",
        organizerIds: [],
        playDate: "2024-06-09",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e897182748786076@facebook.com",
        slugExtra: "",
        name: "KIŠ a Pade na Pade: Longforma",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/897182748786076/",
        organizerIds: ["kis", "padenapade"],
        playDate: "2024-06-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e321703254089594@facebook.com",
        slugExtra: "",
        name: "Zápas Bafni vs. hosté",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/321703254089594/",
        organizerIds: ["bafni"],
        playDate: "2024-06-12",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e6827212794045313@facebook.com",
        slugExtra: "",
        name: "Just!Impro show ve Švanďáku",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/6827212780711981/?event_time_id=6827212794045313",
        organizerIds: ["justimpro"],
        playDate: "2024-06-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2830483910422813@facebook.com",
        slugExtra: "",
        name: "Hříšné historky - improvizační představení zdarma na festivalu Naruby",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2830483910422813/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-06-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e337277359334133@facebook.com",
        slugExtra: "",
        name: "🌟 𝘿𝙀𝙐𝙎 𝙀𝙓 𝙈𝙄𝙆𝙍𝙊𝙁𝙊𝙉𝘼 🌟 Miřenka Čechová",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/337277359334133/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-06-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1001311734943150@facebook.com",
        slugExtra: "",
        name: "Impro vlaková show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1001311734943150/",
        organizerIds: ["vip"],
        playDate: "2024-06-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "1",
        slugExtra: "",
        name: "Květnov fest 2024",
        eventType: "play",
        websiteUrl: "https://umenivpraci.cz/festival/",
        organizerIds: ["monikanevolova"],
        playDate: "2024-06-15",
        district: "Havlíčkův Brod",
        language: "cs",
      },
      {
        id: "e1094471655005354@facebook.com",
        slugExtra: "",
        name: "IMPROVIZACE víkendový kurz pro každého",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1094471655005354/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-06-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1087829645649709@facebook.com",
        slugExtra: "",
        name: "Ship of the Damned (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1087829645649709/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-06-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "e765685958680410@facebook.com",
        slugExtra: "",
        name: "Kecky s Jindrou a Andy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/765685958680410/",
        organizerIds: ["kecky"],
        playDate: "2024-06-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e469698495628944@facebook.com",
        slugExtra: "",
        name: "Fofr ve Varech",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/469698495628944/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-06-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1235214370787578@facebook.com",
        slugExtra: "",
        name: "All Imp – Párty desetiletí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1235214370787578/",
        organizerIds: ["allimp"],
        playDate: "2024-06-18",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e1670850797086642@facebook.com",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov / IMPROvariace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1670850783753310/?event_time_id=1670850797086642",
        organizerIds: ["improvariace"],
        playDate: "2024-06-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e899403524983586@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with Script for Brains (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/899403501650255/?event_time_id=899403524983586",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-06-21",
        district: "Praha",
        language: "en",
      },
      {
        id: "e964358044998761@facebook.com",
        slugExtra: "",
        name: "Po roce navostro!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/964358044998761/",
        organizerIds: ["alibiprochyby"],
        playDate: "2024-06-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e450264734039171@facebook.com",
        slugExtra: "",
        name: "Víkendový kurz aplikované improvizace v Plzni",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/450264734039171/",
        organizerIds: ["nobuco"],
        playDate: "2024-06-29",
        district: "Plzeň",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Červenec",
    monthDate: "2024-07",
    events: [
      {
        id: "e1501011240793349@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011240793349",
        organizerIds: [],
        playDate: "2024-07-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1670850793753309@facebook.com",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov / IMPROvariace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1670850783753310/?event_time_id=1670850793753309",
        organizerIds: ["improvariace"],
        playDate: "2024-07-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011237460016@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011237460016",
        organizerIds: [],
        playDate: "2024-07-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e707947494590163@facebook.com",
        slugExtra: "",
        name: "🍇🍉🍒🍓IMPROCAMP",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/707947494590163/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-07-10",
        district: "Středočeský kraj",
        language: "cs",
      },
      {
        id: "e1501011247460015@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011247460015",
        organizerIds: [],
        playDate: "2024-07-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e263369609779641@facebook.com",
        slugExtra: "",
        name: "2024 AIN Global Conference (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/263369609779641/",
        organizerIds: [],
        playDate: "2024-07-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1501011244126682@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011244126682",
        organizerIds: [],
        playDate: "2024-07-23",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e768300588421500@facebook.com",
        slugExtra: "",
        name: "INBAL LORI & TIM ORR IN PRAGUE",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/768300588421500/",
        organizerIds: ["monikanevolova"],
        playDate: "2024-07-28",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1129721338110776@facebook.com",
        slugExtra: "",
        name: "Šejkspírovské improvizační slavnosti, uvedení první",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1129721338110776/",
        organizerIds: ["sis"],
        playDate: "2024-07-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1100519044338442@facebook.com",
        slugExtra: "1",
        name: "Piknik @ Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1100519044338442/",
        organizerIds: [],
        playDate: "2024-07-30",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011224126684@facebook.com",
        slugExtra: "2",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011224126684",
        organizerIds: [],
        playDate: "2024-07-30",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1734322570413622@facebook.com",
        slugExtra: "",
        name: "Camp improvizace s Martinem Vasquezem a Andreou Moličovou",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1734322570413622/",
        organizerIds: ["paletaci"],
        playDate: "2024-07-30",
        district: "Středočeský kraj",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Srpen",
    monthDate: "2024-08",
    events: [
      {
        id: "e369217108793362@facebook.com",
        slugExtra: "",
        name: "Letní improvizační tábor 2024",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/369217108793362/",
        organizerIds: ["plamenaci"],
        playDate: "2024-08-04",
        district: "Středočeský kraj",
        language: "cs",
      },
      {
        id: "e1181389462994651@facebook.com",
        slugExtra: "",
        name: "improVýkřik: OpenAir",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1181389462994651/",
        organizerIds: ["improvykrik"],
        playDate: "2024-08-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011250793348@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011250793348",
        organizerIds: [],
        playDate: "2024-08-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011227460017@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011227460017",
        organizerIds: [],
        playDate: "2024-08-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1670850803753308@facebook.com",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov / IMPROvariace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1670850783753310/?event_time_id=1670850803753308",
        organizerIds: ["improvariace"],
        playDate: "2024-08-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e787362720251946@facebook.com",
        slugExtra: "",
        name: "Terapie županem",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/787362720251946/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-08-15",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e391620633467217@facebook.com",
        slugExtra: "",
        name: "Láďa Karda: Improvizace z bodu nula workshop",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/391620633467217/",
        organizerIds: [],
        playDate: "2024-08-17",
        district: "Bratislava",
        language: "cs",
      },
      {
        id: "e993450632484871@facebook.com",
        slugExtra: "",
        name: "Šejkspírovské improvizační slavnosti, uvedení druhé",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/993450632484871/",
        organizerIds: ["sis"],
        playDate: "2024-08-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011230793350@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011230793350",
        organizerIds: [],
        playDate: "2024-08-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1501011234126683@facebook.com",
        slugExtra: "",
        name: "Letní improúterky 2024",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1501011220793351/?event_time_id=1501011234126683",
        organizerIds: [],
        playDate: "2024-08-27",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1227607398261677@facebook.com",
        slugExtra: "",
        name: "Láďa Karda's untitled project: premiéra | improvizace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1227607398261677/",
        organizerIds: [],
        playDate: "2024-08-28",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e7756154474491393@facebook.com",
        slugExtra: "",
        name: "Masterclass Improvizace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7756154474491393/",
        organizerIds: [],
        playDate: "2024-08-31",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Září",
    monthDate: "2024-09",
    events: [
      {
        id: "e1491047591771741@facebook.com",
        slugExtra: "",
        name: "Vybal kufry",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1491047591771741/",
        organizerIds: ["divadlo301"],
        playDate: "2024-09-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e8045206032181532@facebook.com",
        slugExtra: "",
        name: "IMPROVIZACE k Osobnostnímu rozvoji",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/8045206032181532/",
        organizerIds: ["skola21"],
        playDate: "2024-09-04",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1626128707957712@facebook.com",
        slugExtra: "",
        name: "CHIMÉRA /workshop s KLUKY/",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1626128707957712/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-09-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1158400752063662@facebook.com",
        slugExtra: "",
        name: "IMPROVIZACE k Osobnostnímu rozvoji",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1158400752063662/",
        organizerIds: ["skola21"],
        playDate: "2024-09-07",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1670850790419976@facebook.com",
        slugExtra: "",
        name: "Letní IMPROshow na Terase Smíchov / IMPROvariace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1670850783753310/?event_time_id=1670850790419976",
        organizerIds: ["improvariace"],
        playDate: "2024-09-10",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e436245059372323@facebook.com",
        slugExtra: "",
        name: "Vosto5: Stand'artní kabaret",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/436245059372323/",
        organizerIds: ["standartnikabaret"],
        playDate: "2024-09-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e389086560595943@facebook.com",
        slugExtra: "",
        name: "AIN Conference - Echoes",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/389086560595943/",
        organizerIds: ["skolaimprovizace"],
        playDate: "2024-09-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e501686452386510@facebook.com",
        slugExtra: "",
        name: "DOPOLEDNÍ Rozlety s Peťou (podzim 2024)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/501686452386510/",
        organizerIds: [],
        playDate: "2024-09-12",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1003708794556645@facebook.com",
        slugExtra: "",
        name: "Volná jízda vol. 3: festival čisté improvizace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1003708784556646/?event_time_id=1003708794556645",
        organizerIds: ["standartnikabaret"],
        playDate: "2024-09-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1253636138961635@facebook.com",
        slugExtra: "",
        name: "Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1253636138961635/",
        organizerIds: ["paletaci"],
        playDate: "2024-09-13",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e1003708787889979@facebook.com",
        slugExtra: "",
        name: "Volná jízda vol. 3: festival čisté improvizace",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/1003708784556646/?event_time_id=1003708787889979",
        organizerIds: ["standartnikabaret"],
        playDate: "2024-09-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1043088857471671@facebook.com",
        slugExtra: "",
        name: "Divadlo naslepo - rozhlasová hra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1043088857471671/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-09-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1119351712698209@facebook.com",
        slugExtra: "",
        name: "Večer Anonymní Improvizace ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1119351712698209/",
        organizerIds: ["cai"],
        playDate: "2024-09-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1168794281044727@facebook.com",
        slugExtra: "",
        name: "𝑨𝒎𝒃𝒊𝒄𝒆 𝒂𝒌𝒗𝒂𝒓𝒊𝒋𝒏𝒊́𝒄𝒉 𝒓𝒚𝒃𝒊𝒄̌𝒆𝒌 /KLUCI x LOĎ/",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1168794281044727/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-09-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1212857466618613@facebook.com",
        slugExtra: "",
        name: "Improv workshop with Greg Kersik - improv Freestyle  (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1212857466618613/",
        organizerIds: ["blr"],
        playDate: "2024-09-19",
        district: "Praha",
        language: "en",
      },
      {
        id: "e401434262982970@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with SfB! (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/401434246316305/?event_time_id=401434262982970",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-09-20",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1049500902889755@facebook.com",
        slugExtra: "",
        name: "The IMPERFECTIONISTS RETURN! (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1049500902889755/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-09-21",
        district: "Praha",
        language: "en",
      },
      {
        id: "e491351780300303@facebook.com",
        slugExtra: "1",
        name: "Improvizace pro rodiče s dětmi 6-9 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/491351773633637/?event_time_id=491351780300303",
        organizerIds: [],
        playDate: "2024-09-22",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e2298078750540699@facebook.com",
        slugExtra: "2",
        name: "Improvizace pro rodiče s dětmi 9-12 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/2298078740540700/?event_time_id=2298078750540699",
        organizerIds: [],
        playDate: "2024-09-22",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e528189366451592@facebook.com",
        slugExtra: "",
        name: "Swifthood Hollywood",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/528189366451592/",
        organizerIds: ["marianazacharova"],
        playDate: "2024-09-24",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e869063198760876@facebook.com",
        slugExtra: "",
        name: "Zápas v divadelní improvizaci: Čechova fuška versus něčí jehněčí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/869063198760876/",
        organizerIds: ["cechovovafuska", "necijehneci"],
        playDate: "2024-09-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e491883700458742@facebook.com",
        slugExtra: "",
        name: "Improshow: Blančiny bílé boty",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/491883700458742/",
        organizerIds: ["blancinybileboty"],
        playDate: "2024-09-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1436779986996040@facebook.com",
        slugExtra: "",
        name: "Rejžové v rejži",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1436779986996040/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-09-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Říjen",
    monthDate: "2024-10",
    events: [
      {
        id: "e904992174854664@facebook.com",
        slugExtra: "",
        name: "Sci-fi improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/904992174854664/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-10-01",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1337571633888582@facebook.com",
        slugExtra: "",
        name: "Počátek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1337571633888582/",
        organizerIds: ["divadlo301"],
        playDate: "2024-10-01",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e925447906268131@facebook.com",
        slugExtra: "",
        name: "Divadelní improvizace pro nezadané 30-44",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/925447906268131/",
        organizerIds: ["kopr"],
        playDate: "2024-10-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e508076528822094@facebook.com",
        slugExtra: "",
        name: "OTEVŘENÝ TRÉNINK s VIP impro",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/508076528822094/",
        organizerIds: ["vip"],
        playDate: "2024-10-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e3856666564603675@facebook.com",
        slugExtra: "",
        name: "Improv fun with the Imperfectionists! (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3856666564603675/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-10-05",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1061925408874213@facebook.com",
        slugExtra: "",
        name: "K.Op.R. vs FrMol",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1061925408874213/",
        organizerIds: ["kopr"],
        playDate: "2024-10-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e8653948307971238@facebook.com",
        slugExtra: "",
        name: "Summer Impro JAM",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/8653948307971238/",
        organizerIds: ["alibiprochyby"],
        playDate: "2024-10-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1268804400962102@facebook.com",
        slugExtra: "",
        name: "Improshow Meandrů - zahájení sezóny",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1268804400962102/",
        organizerIds: ["meandry"],
        playDate: "2024-10-09",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e3854150761528944@facebook.com",
        slugExtra: "",
        name: "Improzápas LÍSKY vs. VALOUNY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/3854150761528944/",
        organizerIds: ["lisky", "valouny"],
        playDate: "2024-10-11",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "e7872512152860339@facebook.com",
        slugExtra: "",
        name: "IMJOY VS B.I.Z.O.N.I. - ZÁPAS V DIVADELNÍ IMPROVIZACI",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/7872512152860339/",
        organizerIds: ["imjoy", "bizoni"],
        playDate: "2024-10-12",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e479423545071206@facebook.com",
        slugExtra: "",
        name: "IMPROVIZACE k Osobnostnímu rozvoji",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/479423545071206/",
        organizerIds: ["skola21"],
        playDate: "2024-10-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e919147170268550@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: 1 + 1 = VESMÍR",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/919147170268550/",
        organizerIds: ["therandoms"],
        playDate: "2024-10-16",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e553814113976318@facebook.com",
        slugExtra: "",
        name: "Souboj příběhů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/553814113976318/",
        organizerIds: ["bafni"],
        playDate: "2024-10-16",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1543714486288527@facebook.com",
        slugExtra: "",
        name: "Exit 87 Improv Comedy Show (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1543714486288527/",
        organizerIds: ["exit87"],
        playDate: "2024-10-17",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1019779450153159@facebook.com",
        slugExtra: "",
        name: "Kurz improvizace s Marianou Zacharovou",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1019779450153159/",
        organizerIds: ["marianazacharova"],
        playDate: "2024-10-18",
        district: "Plzeň",
        language: "cs",
      },
      {
        id: "e507916918792533@facebook.com",
        slugExtra: "2",
        name: "IMPRO FLIRT: rychlé základy improvizace pro každodenní spokojený život",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/507916918792533/",
        organizerIds: ["marianazacharova"],
        playDate: "2024-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e401434259649637@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with SfB! (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/401434246316305/?event_time_id=401434259649637",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-10-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1409259176436562@facebook.com",
        slugExtra: "",
        name: "Meisner Acting Technique with Hendrik Martz - VYPRODÁNO",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1409259176436562/",
        organizerIds: [],
        playDate: "2024-10-18",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1255642015614598@facebook.com",
        slugExtra: "",
        name: "🧁ImproZÁKUSKY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1255642015614598/",
        organizerIds: ["katerinaburesova"],
        playDate: "2024-10-18",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1683643259079327@facebook.com",
        slugExtra: "",
        name: "Strašidelná improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1683643259079327/",
        organizerIds: ["paletaci"],
        playDate: "2024-10-18",
        district: "Pardubice",
        language: "cs",
      },
      {
        id: "e1923196114825950@facebook.com",
        slugExtra: "",
        name: "Impro sobota - Radost, hravost, tvořivost",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1923196114825950/",
        organizerIds: [],
        playDate: "2024-10-19",
        district: "Plzeň",
        language: "cs",
      },
      {
        id: "e931740245635323@facebook.com",
        slugExtra: "",
        name: "Impro Motel",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/931740245635323/",
        organizerIds: ["nepripraveni"],
        playDate: "2024-10-20",
        district: "Havlíčkův Brod",
        language: "cs",
      },
      {
        id: "e1199157078012351@facebook.com",
        slugExtra: "",
        name: "Tajemství Vesmíru (Transilvánská Magoška)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1199157078012351/",
        organizerIds: ["saysomethingcz"],
        playDate: "2024-10-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e491351776966970@facebook.com",
        slugExtra: "",
        name: "Improvizace pro rodiče s dětmi 6-9 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/491351773633637/?event_time_id=491351776966970",
        organizerIds: [],
        playDate: "2024-10-20",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e2298078753874032@facebook.com",
        slugExtra: "2",
        name: "Improvizace pro rodiče s dětmi 9-12 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/2298078740540700/?event_time_id=2298078753874032",
        organizerIds: [],
        playDate: "2024-10-20",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1228673074991928@facebook.com",
        slugExtra: "",
        name: "Říčanské improhraní",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1228673074991928/",
        organizerIds: ["improvykrik"],
        playDate: "2024-10-21",
        district: "Říčany",
        language: "cs",
      },
      {
        id: "e1254643999046151@facebook.com",
        slugExtra: "",
        name: "KOMIKAZE - Komediálně improvizovaná show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1254643999046151/",
        organizerIds: ["kokoloko"],
        playDate: "2024-10-21",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e2057645638026765@facebook.com",
        slugExtra: "",
        name: "Gurmánská improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2057645638026765/",
        organizerIds: ["vyvar"],
        playDate: "2024-10-22",
        district: "Ostrava",
        language: "cs",
      },
      {
        id: "e1053788449701182@facebook.com",
        slugExtra: "",
        name: "KOMIKAZE - Komediálně improvizovaná show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1053788449701182/",
        organizerIds: ["kokoloko"],
        playDate: "2024-10-22",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "e1557399478214394@facebook.com",
        slugExtra: "",
        name: "Improshow v nových kategoriích",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1557399478214394/",
        organizerIds: ["bizoni"],
        playDate: "2024-10-23",
        district: "Hradec Králové",
        language: "cs",
      },
      {
        id: "e1795134467561413@facebook.com",
        slugExtra: "",
        name: "V HLAVĚ / celovečerní improvizovaná hra",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1795134467561413/",
        organizerIds: ["improvariace"],
        playDate: "2024-10-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e2343177399347644@facebook.com",
        slugExtra: "",
        name: "Improshow na mýtince",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2343177399347644/",
        organizerIds: ["vip"],
        playDate: "2024-10-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1010615970762878@facebook.com",
        slugExtra: "",
        name: "IMPRA – Standardka (ProART Festival 2024 v Olomouci)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1010615970762878/",
        organizerIds: ["impra"],
        playDate: "2024-10-27",
        district: "Olomouc",
        language: "cs",
      },
      {
        id: "e576528574834470@facebook.com",
        slugExtra: "",
        name: "Improv Workshop - The Slacker Format (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/576528574834470/",
        organizerIds: ["nickford"],
        playDate: "2024-10-27",
        district: "Praha",
        language: "en",
      },
      {
        id: "e2552509018279447@facebook.com",
        slugExtra: "",
        name: "USA Super Impro Show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2552509018279447/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-10-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Listopad",
    monthDate: "2024-11",
    events: [
      {
        id: "e1619449758615618@facebook.com",
        slugExtra: "",
        name: "🥗ALL YOU CAN EAT impro ws",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1619449758615618/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-11-02",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1074880030959735@facebook.com",
        slugExtra: "",
        name: "THE SPOOKY SPECTACULAR (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1074880030959735/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-11-02",
        district: "Praha",
        language: "en",
      },

      {
        id: "e509767588639147@facebook.com",
        slugExtra: "",
        name: "PLAY OFF ZÁPAS",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/509767588639147/",
        organizerIds: ["valouny"],
        playDate: "2024-11-02",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "e2777957879030501@facebook.com",
        slugExtra: "",
        name: "GILTY PLEŽR: WESTERN / IMPROvariace & Divadlo Nula",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/2777957879030501/",
        organizerIds: ["improvariace"],
        playDate: "2024-11-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1568482087120818@facebook.com",
        slugExtra: "",
        name: "Přilož",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1568482087120818/",
        organizerIds: ["divadlo301"],
        playDate: "2024-11-05",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e534854192572254@facebook.com",
        slugExtra: "",
        name: "Komikaze: IMPRO SHOW",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/534854192572254/",
        organizerIds: [],
        playDate: "2024-11-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e458405810588133@facebook.com",
        slugExtra: "",
        name: "Survivor 2.0",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/458405810588133/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-11-08",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e851727146951368@facebook.com",
        slugExtra: "",
        name: "K.Op.R. vs Čechovova fuška",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/851727146951368/",
        organizerIds: ["kopr", "cechovovafuska"],
        playDate: "2024-11-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1218696876134250@facebook.com",
        slugExtra: "",
        name: "Nedělní pohádka: IMJOY & zase IMP(R)OHÁDKA",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1218696876134250/",
        organizerIds: [],
        playDate: "2024-11-10",
        district: "Liberec",
        language: "cs",
      },
      {
        id: "e511358425156885@facebook.com",
        slugExtra: "",
        name: "Zápas v divadelní improvizaci: NĚČÍ JEHNĚČÍ versus NEVÍM ČÍ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/511358425156885/",
        organizerIds: ["necijehneci"],
        playDate: "2024-11-14",
        district: "Říčany",
        language: "cs",
      },
      {
        id: "e401434256316304@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with SfB! (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/401434246316305/?event_time_id=401434256316304",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-11-15",
        district: "Praha",
        language: "en",
      },
      {
        id: "e413283378491053@facebook.com",
        slugExtra: "",
        name: "Improvizační Řez: Joke Division (CZ) + Tak Wyszło (PL)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/413283378491053/",
        organizerIds: [],
        playDate: "2024-11-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1062896785318763@facebook.com",
        slugExtra: "",
        name: "T. T. T. VEĎ IMPRO a NECH se VÉST IV volné pokračování",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1062896785318763/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-11-16",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e8950895761589375@facebook.com",
        slugExtra: "",
        name: "Tarantino - improvizované představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/8950895761589375/",
        organizerIds: [],
        playDate: "2024-11-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1201875147548503@facebook.com",
        slugExtra: "",
        name: "🌟 𝐷𝐸𝑈𝑆 𝐸𝑋 𝑀𝐼𝐾𝑅𝑂𝐹𝑂𝑁𝐴 🌟 Tomáš Dianiška",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1201875147548503/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2024-11-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1196842474747036@facebook.com",
        slugExtra: "",
        name: "Otevřené Improstředy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1196842474747036/",
        organizerIds: ["improdva"],
        playDate: "2024-11-20",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e554090887144956@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: Nuda",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/554090887144956/",
        organizerIds: ["therandoms"],
        playDate: "2024-11-21",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1179520803144738@facebook.com",
        slugExtra: "",
        name: "IMPRO ZÁPAS",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1179520803144738/",
        organizerIds: ["dortvkapse"],
        playDate: "2024-11-21",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e347330871779639@facebook.com",
        slugExtra: "",
        name: "Improshow v Brně",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/347330871779639/",
        organizerIds: ["bizoni"],
        playDate: "2024-11-22",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1975506546250575@facebook.com",
        slugExtra: "",
        name: "Vyšetřování inspektora Parkera – celovečerní improvizovaná detektivka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1975506546250575/",
        organizerIds: ["vip"],
        playDate: "2024-11-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e581168031263940@facebook.com",
        slugExtra: "",
        name: "🧁🧁improZÁKUSKY DUO",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/581168031263940/",
        organizerIds: ["michaelapuchalka"],
        playDate: "2024-11-22",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e599572485987652@facebook.com",
        slugExtra: "",
        name: "Workshop na Sýpce",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/599572485987652/",
        organizerIds: ["lisky"],
        playDate: "2024-11-23",
        district: "Boskovice",
        language: "cs",
      },
      {
        id: "e491351786966969@facebook.com",
        slugExtra: "1",
        name: "Improvizace pro rodiče s dětmi 6-9 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/491351773633637/?event_time_id=491351786966969",
        organizerIds: [],
        playDate: "2024-11-24",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e2298078743874033@facebook.com",
        slugExtra: "2",
        name: "Improvizace pro rodiče s dětmi 9-12 let",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/2298078740540700/?event_time_id=2298078743874033",
        organizerIds: [],
        playDate: "2024-11-24",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1557907672270404@facebook.com",
        slugExtra: "",
        name: "Improv Workshop - I just want to feel! (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1557907672270404/",
        organizerIds: ["nickford"],
        playDate: "2024-11-24",
        district: "Praha",
        language: "en",
      },
      {
        id: "e1170874544010843@facebook.com",
        slugExtra: "",
        name: "Šejkspírovský improsionismus",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1170874544010843/",
        organizerIds: ["sis"],
        playDate: "2024-11-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1588009868817916@facebook.com",
        slugExtra: "",
        name: "Club anonymní improvizace ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1588009868817916/",
        organizerIds: ["cai"],
        playDate: "2024-11-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e4055682188021926@facebook.com",
        slugExtra: "",
        name: "Swifthood Hollywood",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/4055682188021926/",
        organizerIds: [],
        playDate: "2024-11-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1092755565779877@facebook.com",
        slugExtra: "",
        name: "Improvizační jednotka TLAK: Soundtrack tvýho života Vol.1",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1092755565779877/",
        organizerIds: [],
        playDate: "2024-11-26",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1079019223393497@facebook.com",
        slugExtra: "",
        name: "NeDivSe: DOVOPRAVDY",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1079019223393497/",
        organizerIds: ["nedivse"],
        playDate: "2024-11-28",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1200935574459708@facebook.com",
        slugExtra: "",
        name: "Seriál",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1200935574459708/",
        organizerIds: ["kis"],
        playDate: "2024-11-29",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Prosinec",
    monthDate: "2024-12",
    events: [
      {
        id: "e1639656096619459@facebook.com",
        slugExtra: "",
        name: "Improv Workshop - Be quiet and get off the stage! (EN)",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1639656096619459/",
        organizerIds: ["nickford"],
        playDate: "2024-12-01",
        district: "Praha",
        language: "en",
      },
      {
        id: "e952197026806482@facebook.com",
        slugExtra: "",
        name: "Vánoční",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/952197026806482/",
        organizerIds: ["divadlo301"],
        playDate: "2024-12-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e972920134630320@facebook.com",
        slugExtra: "",
        name: "Improvizační show absolventů semestrálního kurzu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/972920134630320/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-12-03",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1227198328604730@facebook.com",
        slugExtra: "",
        name: "The Randoms improvizují: Hříšníci",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1227198328604730/",
        organizerIds: [],
        playDate: "2024-12-04",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e1097136645142807@facebook.com",
        slugExtra: "",
        name: "Improshow Meandrů",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1097136645142807/",
        organizerIds: ["meandry"],
        playDate: "2024-12-04",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e548596377954052@facebook.com",
        slugExtra: "",
        name: "Stromeček BEZ CHYBY!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/548596377954052/",
        organizerIds: ["alibiprochyby"],
        playDate: "2024-12-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e554225227346043@facebook.com",
        slugExtra: "",
        name: "Lovestorky - Improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/554225227346043/",
        organizerIds: ["polocasnapadu"],
        playDate: "2024-12-06",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e535463739332645@facebook.com",
        slugExtra: "",
        name: "KALEIDOSCOPE  (EN)",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/535463739332645/",
        organizerIds: ["imperfectionists"],
        playDate: "2024-12-07",
        district: "Praha",
        language: "en",
      },
      {
        id: "e8173649769407911@facebook.com",
        slugExtra: "",
        name: "VALOUNY vs. FRMOL",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/8173649769407911/",
        organizerIds: ["valouny"],
        playDate: "2024-12-07",
        district: "Valašské Meziříčí",
        language: "cs",
      },
      {
        id: "e1967696687077902@facebook.com",
        slugExtra: "",
        name: "Fuška jenom zlatá - šťastný impro",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1967696687077902/",
        organizerIds: ["cechovovafuska"],
        playDate: "2024-12-08",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e528717263119876@facebook.com",
        slugExtra: "",
        name: "Vánoční improvizovaná show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/528717263119876/",
        organizerIds: ["nepripraveni"],
        playDate: "2024-12-08",
        district: "Havlíčkův Brod",
        language: "cs",
      },
      {
        id: "e1089993919579913@facebook.com",
        slugExtra: "",
        name: "Za studena lisované příběhy",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1089993919579913/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-12-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1354126942663244@facebook.com",
        slugExtra: "",
        name: "321Jedem! Host: Tomáš Šebek",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1354126942663244/",
        organizerIds: ["321jedem"],
        playDate: "2024-12-09",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e451898384048705@facebook.com",
        slugExtra: "",
        name: "Ukázková lekce IMPROVIZACE zdarma",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/451898384048705/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2024-12-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1163064791907677@facebook.com",
        slugExtra: "",
        name: "Wednesday Weekly Workshops (EN)",
        eventType: "workshop",
        websiteUrl:
          "https://www.facebook.com/events/1163064781907678/?event_time_id=1163064791907677",
        organizerIds: ["blr"],
        playDate: "2024-12-11",
        district: "Brno",
        language: "en",
      },
      {
        id: "e590854320033282@facebook.com",
        slugExtra: "",
        name: "Improvizujeme!",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/590854320033282/",
        organizerIds: ["improvizacecz"],
        playDate: "2024-12-11",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e579319841240582@facebook.com",
        slugExtra: "",
        name: "Fofr v Motelu",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/579319841240582/",
        organizerIds: ["fofrvzupanu"],
        playDate: "2024-12-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e401434252982971@facebook.com",
        slugExtra: "",
        name: "Live Improv Comedy with SfB! (EN)",
        eventType: "play",
        websiteUrl:
          "https://www.facebook.com/events/401434246316305/?event_time_id=401434252982971",
        organizerIds: ["scriptforbrains"],
        playDate: "2024-12-13",
        district: "Praha",
        language: "en",
      },
      {
        id: "e552874834032947@facebook.com",
        slugExtra: "",
        name: "Vánoční besídka",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/552874834032947/",
        organizerIds: ["improvykrik"],
        playDate: "2024-12-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e776970027923572@facebook.com",
        slugExtra: "",
        name: "🧁 improZÁKUSKY představení",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/776970027923572/",
        organizerIds: ["katerinaburesova", "michaelapuchalka"],
        playDate: "2024-12-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1966992623800414@facebook.com",
        slugExtra: "",
        name: "Adventní improshow",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1966992623800414/",
        organizerIds: ["vip"],
        playDate: "2024-12-13",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e537189702649262@facebook.com",
        slugExtra: "",
        name: "Tajemství Vesmíru - Ve stínu kapr kadí",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/537189702649262/",
        organizerIds: ["saysomethingcz"],
        playDate: "2024-12-14",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e710958871241660@facebook.com",
        slugExtra: "",
        name: "Vánoční show",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/710958871241660/",
        organizerIds: ["bafni"],
        playDate: "2024-12-18",
        district: "Brno",
        language: "cs",
      },
      {
        id: "e873918144920461@facebook.com",
        slugExtra: "",
        name: "Vánoční Improvizační Řez: Joke Division + NeDivSe",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/873918144920461/",
        organizerIds: ["nedivse"],
        playDate: "2024-12-19",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e885833160204196@facebook.com",
        slugExtra: "",
        name: "Blančiny bílé boty - VÁNOČNÍ IMPROSHOW",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/885833160204196/",
        organizerIds: [],
        playDate: "2024-12-19",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Leden",
    monthDate: "2025-01",
    events: [
      {
        id: "e1770209540458336@facebook.com",
        slugExtra: "",
        name: "Improv workshops with Andrew Hefler",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/1770209540458336/",
        organizerIds: [],
        playDate: "2025-01-11",
        district: "Praha",
        language: "en",
      },
      {
        id: "e576269001519678@facebook.com",
        slugExtra: "",
        name: "HRY DO KOLEKTIVU, ke kterým nic nepotřebujete - jen je znát",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/576269001519678/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2025-01-12",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1616200969108938@facebook.com",
        slugExtra: "",
        name: "𝗦𝘃𝗲̌𝗱𝗼𝗺𝗶𝘁𝗲̌ 𝗻𝗲𝗽𝗿̌𝗶𝗽𝗿𝗮𝘃𝗲𝗻𝗮́ 𝘀𝘁𝗿̌𝗶́𝗱𝗮𝗰̌𝗸𝗮: 𝗗𝗘𝗞𝗞𝗔𝗗𝗔𝗡𝗖𝗘𝗥𝗦",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1616200969108938/",
        organizerIds: ["myklucicospoluchodime"],
        playDate: "2025-01-17",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e971162124838625@facebook.com",
        slugExtra: "",
        name: "Víkendový kurz: Umění improvizace",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/971162124838625/",
        organizerIds: ["kabinet-impro-dovednosti"],
        playDate: "2025-01-25",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1124939335638411@facebook.com",
        slugExtra: "",
        name: "Improvizační jednotka TLAK - Věty tvýho srdce, vydání 1. ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1124939335638411/",
        organizerIds: [],
        playDate: "2025-01-29",
        district: "Praha",
        language: "cs",
      },
      {
        id: "e1075475173986764@facebook.com",
        slugExtra: "",
        name: "Večer Anonymní Improvizace ",
        eventType: "play",
        websiteUrl: "https://www.facebook.com/events/1075475173986764/",
        organizerIds: ["cai"],
        playDate: "2025-01-30",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Březen",
    monthDate: "2025-03",
    events: [
      {
        id: "e1900639180447345@facebook.com",
        slugExtra: "",
        name: "2nd PRAGUE IMPROVATHON: A Ukraine Fundraiser",
        eventType: "jam",
        websiteUrl: "https://www.facebook.com/events/1900639180447345/",
        organizerIds: [],
        playDate: "2025-03-21",
        district: "Praha",
        language: "cs",
      },
    ],
  },
  {
    monthName: "Květen",
    monthDate: "2025-05",
    events: [
      {
        id: "e531621113010930@facebook.com",
        slugExtra: "",
        name: "Víkendový workshop Improvizace a la Meisner KVĚTEN 2025",
        eventType: "workshop",
        websiteUrl: "https://www.facebook.com/events/531621113010930/",
        organizerIds: [],
        playDate: "2025-05-16",
        district: "Praha",
        language: "cs",
      },
    ],
  },
];
